.inbound-visibility-search {
  margin: 2px;
  max-width: 350px;
  display: inline-block;
  align-self: flex-end;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
              0px 4px 5px 0px rgb(0 0 0 / 14%),
              0px 1px 10px 0px rgb(0 0 0 / 12%);
              
}

@media only screen and (max-width: 1080px) {
  .inbound-visibility-search-tab {
    min-width: 73px !important;
  }

  .inbound-visibility-search .dynamic-search-container .label {
    width: 255px;
  }

  .inbound-visibility-search .dynamic-search-container input {
    width: 195px;
  }
}