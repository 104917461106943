.simweb_legend {
  display: grid;
}

.simweb_legend_item {
  display: flex;
  justify-content: left;
  padding-bottom: 2px;
}

.simweb_legend_item p {
  position: relative;
  padding-right: 8px;
  font-size: .8em;
  font-Family: 'Helvetica Neue',
}

.simweb_legend_item span {
  height: 14px;
  width: 14px;
  margin: 0 8px;
  border-radius: 3px;
}
