.groupged-bar-chart-legend-container {
  margin-bottom: 10px;
  margin-left: 25px;
}

.groupged-bar-chart-legend-container .header-graph-table {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 800px;
}

.groupged-bar-chart-legend-container .header-data-graphs {
  padding-top: 0;
}

.header-graph-table-title {
  width: 250px;
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
}

.header-graph-table-subtitle {
  font-size: 12px;
  padding: 10px;
}

.total-units, .total-value {
  background-color: var(--whiteDefault);
  color: var(--blackDefault);
  border-radius: 10px;
  padding: 5px 20px;
}