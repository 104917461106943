.scan-product-detail-header-row {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
}

.scan-product-detail-aggregations {
  display: flex;
}

.scan-product-detail-aggregations p {
  font-weight: 600;
  padding: 3px;
  margin: 1px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-bottom: 0;
  padding: 5px;
  height: fit-content;
}

.scan-product-detail-aggregations p span {
  padding-left: 2.5px;
}

.scan-product-detail-aggregations p:nth-of-type(1) {
  margin-top: 1px !important;
}

.scan-product-detail-area-buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.scan-product-detail-area-buttons button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8px;
  background: none;
  border: none;
}

.scan-product-detail-area-buttons button svg {
  -webkit-transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	-moz-transition: all 0.3s;
	-moz-backface-visibility: hidden;
	transition: all 0.3s;
  width: 30px;
  height: 30px;
}

.scan-product-detail-area-buttons button:hover svg {
  -webkit-transform: translateY(20%);
	-moz-transform: translateY(20%);
	-ms-transform: translateY(20%);
  transform: translateY(20%);
  padding-bottom: 4px;
}

.area-button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70px;
  border-bottom: 6px solid var(--grey-disabled1);
}

.area-button-wrapper.selected {
  border-bottom: 6px solid var(--genderAge_BoysLittleKid);
}

.area-button-wrapper span {
  font-weight: 600;
  font-size: 10px;
  color: var(--scanReport-blue-color);
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-top: 10px;
  border-top: 1px solid var(--scanReport-blue-color);
}

.scan-product-gtin-table {
  padding: 30px 0;
}