.options-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
}

.options-container .label {
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.6875em;
  color: #999;
  letter-spacing: 0.02em;
  font-weight: normal;
  margin-left: 1px;
  position: absolute;
  top: -15px;
}

.options-container select {
  font-family: Helvetica, Arial, sans-serif;
  text-transform: none;
  font-size: 0.8125em;
  color: #1A1A1A;
  width: 135px;
  height: 34px;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  background: #FFF;
  padding-left: 8px;
  -webkit-appearance: none;
  outline: none;
  margin: 0;
}

.options-container svg {
  position: absolute;
  top: 13px;
  right: 10px;
  width: 10px;
  height: 10px;
  pointer-events: none;
}
