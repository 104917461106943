.barcode-scanner-container {
  display: flex;
  flex-direction: column;
}

.barcode-scanner-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.mobile .webcam-container {
  text-align: center;
  width: auto;
  height: auto;
}

.webcam-container {
  width: 550px;
  height: 500px;
}

.webcam {
  position: absolute;
}

.barcode-frame-container {
  position: absolute;
  top: 205px;
  left: 150px;
}

.mobile .barcode-frame-container {
  position: absolute;
  top: 230px;
  left: 455px;
}

.barcode-scanner-items-count {
  text-align: center;
  padding-bottom: 35px;
}
