.header-profile-container {
  display: flex;
  justify-content: space-between;
  max-height: 60px;
  align-items: center;
  align-content: space-between;
  margin-right: 15px;
}

.header-profile-container > * {
  text-align: center;
  min-width: 35px;
}

.header-profile-container img {
  display: flex;
  position: relative;
  align-self: center;
  justify-content: center;
  margin-top: 6px;
  height: 20px;
  border: 1px solid var(--blackDefault)
}

.header-profile-container .profile-icon {
  z-index: 1;
}

.header-admin-settings {
  text-align: center;
}

.header-admin-settings {
  all: unset;
  cursor: pointer;
}

.admin-hover-styles {
  display: none;
  position: relative;
  cursor: pointer;
  background: var(--whiteDefault);
  border-radius: 50%;
  height: 2.5em;
  width: 2.5em;
  top: 13px;
  z-index: 1;
}

.admin-settings:hover .admin-hover-styles {
  display: block;
  position: relative;
  transition: 1s ease-in-out;
}

.admin-settings:hover .header-admin-settings {
  z-index: 1;
  position: relative;
  cursor: pointer;
  bottom: 17.5px;
  left: 1px;
}

.admin-settings:hover .header-admin-settings svg path {
  stroke: var(--blackDefault);
}

.accountMenu-dropdown-content {
  display: none;
  position: absolute;
  right: 3px;
  top: 41px;
  min-width: 330px;
  text-align: left;
  background: var(--whiteDefault);
  border-radius: 8px;
  color: #757575;
  overflow: visable !important;
  box-shadow: -2px 4px 2px -2px rgb(229, 229, 229);
  z-index: 1;
}

.accountMenu-dropdown-content ul li:first-child {
  font-weight: bold;
  padding: 14px 0;
  font-size: 14px;
}

.accountMenu-dropdown-content ul li  {
  padding: 5px 0;
  cursor: pointer;
  list-style: none;
  letter-spacing: 1px;
  font-size: 12px;
}

.accountMenu-dropdown-content ul li:nth-last-of-type(1):hover {
  color: var(--blackDefault);
  font-weight: 700;
}

.accountMenu-dropdown-content ul li:nth-child(1), .accountMenu-dropdown-content ul li:nth-child(2) {
  color: var(--blackDefault);
}

.accountMenu-dropdown-content ul li:nth-child(1):hover {
  color: var(--blackDefault);
}

.header-dropdown:hover .accountMenu-dropdown-content {
  display: inherit;
  font-size: 12px;
  transition: 1s ease-in-out;
}

.user-hover-styles {
  display: none;
  position: relative;
  cursor: pointer;
  background: var(--whiteDefault);
  border-radius: 50%;
  height: 2.5em;
  width: 2.5em;
  top: 13px;
  z-index: 1;
  margin-right: 0px;
}

.header-dropdown:hover .user-hover-styles {
  display: block;
  position: relative;
  transition: 1s ease-in-out;
}

.header-dropdown:hover .profile-icon {
  z-index: 1;
  position: relative;
  bottom: 17px;
  cursor: pointer;
  margin: 0px;
}

.header-dropdown:hover .profile-icon path {
  fill: var(--blackDefault);
}

.navDropdownItem-label {
  display: flex;
}

.navDropdownItem-label img {
  display: none;
  margin-left: auto;
}

.accountMenu-dropdown-content  .navDropdownItem-disabled {
  color: var(--grey-disabled1);
}

.sim-menu-header-group .MuiSwitch-root .MuiSwitch-track {
  background-color: #989898;
}

.profile-label{
  width: 150px;
  text-align: right;
} 

@media only screen and (max-width: 1160px) {
  .header-profile-container img {
    display: none;
  }
  .navDropdownItem-label img {
    display: block !important;
  }
  .header-profile-container {
    width: 70px;
  }
}
