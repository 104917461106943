.scan-details-info-container {
  margin: 10px;
  font-size: 1.3em;
}

.scan-details-info-aggregations {
  font-size: 1.5rem;
  font-weight: 500;
}

.scan-details-info-location-aggregations {
  padding: 10px 0;
}

.scan-details-info-location-aggregations-values {
  background-color: var(--whiteDefault);
  padding: 0 5px;
  border-radius: 5px;
}