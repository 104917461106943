.product-movement-page .feature-title {
  margin-top: 15px;
}

.product-movement-page .page-controls {
  margin-left: 50px;
  flex-grow: 1;
  justify-content: space-between;
}

.product-movement-page span.negative {
  color: #f71c2d;
}

.product-movement-summary .feature-header .summary-box {
  margin: 0px;
  width: 240px;
  padding-bottom:20px;
  margin-bottom:30px;
}

.product-movement-summary .feature-header .style-box {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.product-movement-summary .feature-header .date-box {
  font-family: Helvetica, Arial, sans-serif;
  line-height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px 10px 10px;
}

.product-movement-summary .feature-header .key {
  font-weight: normal;
  color: #666;
  font-size: 11px;
  text-transform: uppercase;
}

.product-movement-summary .feature-header .value {
  font-weight: bold;
  color: #1a1a1a;
  font-size: 13px;
  text-transform: uppercase;
}

.spinner svg {
  z-index: 1;
  top: 100px;
}

.rfid-scan-table {
  position: sticky;
}

#simple-dialog-title {
  margin-top: 20px;
  text-align: center;
}

.dialog-spinner-content {
  height: 300px;
  width: 400px;
}

.loadingIndicator {
  height: 0;
}
.product-movement-table-header {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.product-movement-title-label {
  color: #9c9c9c;
}

.product-movement-title-data {
  font-weight: bold;
}

.product-movement-back-button {
  padding-top: 15px;
  padding-bottom: 15px;
}

.product-movement-back-button .sim-button svg path {
  fill: var(--blackDefault) !important;
}

.product-movement-back-button .sim-button:hover svg path {
  fill: var(--whiteDefault) !important;
}

@media only screen and (max-width: 1080px) {
  .product-movement-back-button {
    width: 315px;
  }
}