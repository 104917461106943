.timerWidget_dashboard-card {
  width: 100%;
  text-decoration: none;
  margin: 10px !important;
  min-height: 190px;
  background-color: var(--widgetBackgroundColor);
  border-radius: 20px;
  border: 1.3px solid var(--grey-borders);
  border-radius: 26px !important;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.timerWidget_card-details {
  margin: 0 10px 0 10px;
  display: flex;
}

.timerWidget_text_title {
  display: flex;
}

.timerWidget_text_container {
  margin-left: 10px;
  min-width: 300px;
}

.timerWidget_timer_container {
  min-width: 345px;
  display: flex;
  align-items: center;
}

.timerWidget_text_container, .timerWidget_timer_container {
  color: var(--whiteDefault);
  padding: 20px 0px 20px 20px;
}

.scanType_title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 30px;
  margin: 0;
  padding-bottom: 5px;
}
.scan_date {
  font-weight: bold;
    font-size: 1.1em;
    padding: 10px 0;
}
.timerWidget_scanStatus_description {
  padding: 5px 20px 5px 0px;
  max-width: 94%;
}

.timerWidget_timer {
  font-weight: 400;
  font-size: 1.9em;
  line-height: 30px;
  margin-bottom: 0;
  display:flex;
  flex-direction: column;
}

.timerWidget_timer p:nth-of-type(2n) {
  font-size: 55px;
  line-height: 20px;
  font-weight: bold;
  padding-top: 10px;
}

.timerWidget_toolTip {
  padding: 5px 10px 5px 0px;
}

.timerWidget_scanning_toolTip {
  padding: 12px 20px 5px 0px;
}

.timerWidget_finalScanStatus {
  margin: 0 auto;
  padding: 8px;
  position: relative;
}

.timerWidget_finalScanStatus p {
  font-size: 30px;
  font-weight: bold;
}

.timerWidget_unavailable {
  display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
}

.timerWidget_unavailable p  {
  font-size: 20px;
  line-height: 5px;
  font-weight: bold;
  padding-top: 10px;
}

.timerWidget_card-loading{
  position: relative;
  margin-top: 50px;
}

.timerWidget_timer_loading {
  position: relative;
  margin-bottom: 50px;
}

