.error-modal-container {
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 250px;
  min-width: 400px;
  max-width: 800px;
  background-color: hsl(0,0%,100%);
  border: 1px solid black;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  padding: 30px 50px;
  border-radius: 35px;
  filter: blur(0);
  outline: none;
}

.error-modal-description {
  text-align: center;
  margin: auto;
  font-size: 2em;
}

.error-modal-button {
  margin-top: 2em;
  border-radius: 6px;
  font-weight: bold;
  font-size: larger;
  height: 50px;
  min-width: 75px;
  background: var(--blackDefault);
  padding: 0 24px;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
  color: white;
  border: 1px solid black;
}
