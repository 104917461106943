.scan-report-body-grid {
  display: grid;
  grid-template-columns: minmax(300px, 360px) minmax(695px, 1fr);
  grid-template-areas: "sidebar body";
}

.scan-report-total-calculation, .scan-report-scan-duration {
  margin: 0 0 0 5px;
  top: 70px;
  font-size: 1.5em;
  color: #999;
  white-space: nowrap;
  font-weight: 500;
}

.scan-report-scan-dates {
  margin: 0 0 0 5px;
  top: 70px;
  font-size: 1.2em;
  color: #999;
  white-space: nowrap;
  font-weight: 500;
}

.scan-report-header-aggregations {
  margin: 0 0 0 5px;
  top: 70px;
  font-size: 1.1em;
  white-space: nowrap;
  font-weight: 500;
}

.scan-report-header-location-aggregations {
  margin: 0 0 0 5px;
  top: 70px;
  font-size: 1em;
  color: #999;
  white-space: nowrap;
  font-weight: 500;
}

.scan-report-main-header {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  align-content: space-between;
  justify-content: space-between;
  margin: 0 30px 0px 30px;
}

.scan-report-main-body {
  padding: 0 0 20px 0;
  grid-area: body;
}

.scan-report-footer {
  display: grid;
  padding-bottom: 20px;
  grid-template-columns: 600px 1fr; 
  grid-template-areas: "clearButton pagination";
}

.clear-button-container {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 360px;
  height: 45px;
  margin: 10px 0;
  padding-top: 2px;
  display: flex;
  justify-content: space-between;
}

.clear-button-container button {
  font-size: 0.95em;
  grid-area: clearButton;
  border: 1px solid var(--blackDefault);
}

.clear-scan-report-filters button svg {
  margin-top: 6px;
}

.clear-scan-report-filters .sim-button svg path {
  fill: var(--blackDefault) !important;
}

.clear-scan-report-filters .sim-button:hover svg path {
  fill: var(--whiteDefault) !important;
}

@media only screen and (max-width: 1080px) {
  .clear-button-container {
    width: 315px;
  }
}