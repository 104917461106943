.sim-menu {
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 1299;
  overflow-x: hidden;
  transition: all 0.5s ease-in-out;
  color: var(--whiteDefault);
  width: 100%;
  box-sizing: border-box;
  overflow: inherit;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.sim-menu-header-home-container a {
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: right;
  z-index: 100;
}

.sim-menu .sim-menu-item.header {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 16px;
  color: #1a1a1a;
  height: 60px;
  padding: 20px;
  padding-left: 30px;
  position: relative;
  top: 0;
  z-index: 5;
}

.home-link-string {
  font-weight: 500;
  color: var(--whiteDefault);
  margin-right: 2px;
  margin-bottom: 0px;
}

.version-header {
  font-size: 10px;
  margin-left: 10px;
  color: var(--whiteDefault);
}

.versionRefresh {
  position: relative;
  left: -7px;
}

.versionRefresh .MuiButton-root{
  min-width: 25px;
}

.sim-top-position {
  position: unset !important;
}

.active {
  visibility: visible;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1299;
  overflow-x: visable;
  transition: all 0.5s ease-in-out;
  width: 100%;
}

.navItem-title-container .navItem-group-title {
  padding: 0 20px !important;
}

.navItem-group-container, .navItem-title-container .navItem-group-title {
  padding-bottom: 20px;
  min-width: 180px;
  text-align: center;
}

.navItem-title-container p:first-of-type {
  padding-left: 0 !important;
}

.navItem-title-container p:last-of-type {
  padding-right: 0 !important;
}

p.navItem-group-title {
  margin-bottom: 10px !important;
}

.navItem-title-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.nav-bar-item {
  display: none;
  justify-content: center;
  padding-bottom: 16px;
  padding: 0 25px !important;
}

.nav-bar-item:hover a.navItem-subLink:hover {
  text-decoration: underline;
}

.sim-menu .features {
  margin: auto;
}

.sim-menu .features:hover {
  max-height: 350px;
}

.sim-menu-header-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--widgetBackgroundColor);
}

.sim-menu .text, .sim-menu .g72-plus {
  white-space: nowrap;
  transition: opacity 0.5s ease-in-out;
}

.sim-menu .g72-swoosh {
  color: #fa5400;
}

.sim-menu .g72-chevron-left {
  transition: transform 0.5s ease-in-out;
}

.sim-menu .sim-menu-item.header span.g72-swoosh {
  font-size: 12px;
  margin-right: 8px;
}

.iPad .sim-menu .sim-menu-item.header {
  display: none;
}

.sim-menu a, .sim-menu a:hover {
  text-decoration: none;
  color: inherit;
}

.sim-menu .sim-menu-item {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  white-space: nowrap;
}

.sim-menu .sim-menu-item.selected a:not(.sim-menu-sub-link), .sim-menu .sim-menu-item a:hover, .sim-menu .sim-menu-sub-link.selected {
  font-weight: bold;
}

.sim-menu .sim-menu-item.selected.parent .sim-menu-link {
  font-weight: normal;
}

.sim-menu .badge {
  margin-left: 10px;
  vertical-align: middle;
}

.navItem-group-title {
  line-height: 38px;
  font-size: 16px;
  padding: 10px 0;
  font-family: 'Helvetica Neue Condensed Bold', Arial, sans-serif;
  font-weight: 600;
  border-bottom: 1px solid var(--grey-borders)
}

@media print {
  .sim-menu {
    display: none;
  }
}

/* Loading state */
.sim-menu-null-state {
  display: flex;
  justify-content: center;
  background-color: var(--widgetBackgroundColor);
  width: 100% !important;
  border-left: 100px solid var(--widgetBackgroundColor);
  border-right: 100px solid var(--widgetBackgroundColor);
}

.sim-menu .sim-menu-item.nav-menu-loading {
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
}

.sim-menu-item.nav-menu-loading .flex-row {
  height: 24px;
  border-radius: 20px;
  width: 90%;
  animation-duration: 1.5s;
  background-color: #434244;
  animation: infiniteLoading 2.5s ease-in-out infinite;
  background-size: 1500px 100px;
  position: relative;
}

.sim-menu .sim-menu-item.nav-menu-loading .flex-row .mask-top,
.sim-menu .sim-menu-item.nav-menu-loading .flex-row .mask-bottom,
.sim-menu .sim-menu-item.nav-menu-loading .flex-row .mask-left,
.sim-menu .sim-menu-item.nav-menu-loading .flex-row .mask-right {
  background: #fff;
  width: 100%;
  height: 4px;
  position: absolute;
}

.sim-menu .sim-menu-item.nav-menu-loading .flex-row .mask-top {
  left: 24px;
  top: 0px;
}

.sim-menu .sim-menu-item.nav-menu-loading .flex-row .mask-bottom {
  left: 24px;
  bottom: 0px;
}

.sim-menu .sim-menu-item.nav-menu-loading .flex-row .mask-left {
  left: 24px;
  height: 100%;
  width: 10px;
}

.sim-menu .sim-menu-item.nav-menu-loading .flex-row .mask-right {
  right: 0;
  height: 100%;
  width: 0;
  transition: width 0.5s ease-in-out;
}

@keyframes infiniteLoading {
  0%   {
    background-color: #787678;
    opacity: .75;
  }
  25%  {
    background-color: #787678;
    opacity: .30;
  }
  50%  {
    background-color: #918D91;
    opacity: .55;
  }
  100% {
    background-color: #918D91;
    opacity: .70;
  }
}

@media only screen and (max-width: 1160px) {
  .home-link-string {
    display: none;
  }
  
  .sim-menu .features {
    width: 100%;
    margin: auto;
  }

  .sim-menu .sim-menu-item.header {
    width: 65px;
  }

  .navItem-group-container, .navItem-title-container, .navItem-group-title {
    min-width: 150px !important;
  }
}

@media only screen and (max-width: 900px) {
  .sim-menu .sim-menu-item.header {
    width: 55px;
    padding-left: 20px;
  }
}
