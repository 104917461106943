.display-scan-title-container {
  margin-top: 20px;
}

.display-scan-table-view-footer {
  margin: 30px 0 0 20px;
}

.display-scan-back-button {
  padding-top: 15px;
  padding-bottom: 15px;
}

.display-scan-back-button button svg {
  margin-top: 6px;
}

.display-scan-back-button .sim-button svg path {
  fill: var(--blackDefault) !important;
}

.display-scan-back-button .sim-button:hover svg path {
  fill: var(--whiteDefault) !important;
}

@media only screen and (max-width: 1080px) {
  .display-scan-back-button {
    width: 315px;
  }
}
