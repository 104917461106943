.inbound-visibility-facets {
  height: 100%;
  margin-right: 10px;
  overflow-x: hidden;
  flex-shrink: 0;
  flex-basis: 350px;
  margin-bottom: 60px;
}

.facets-container {
  max-height: 250px;
  overflow-y: auto;
  padding-right: 5px;
}

.aggregation-seperator {
  margin-left: 5px;
  margin-right: 5px;
}

.inbound-visibility-po-number, .tooltip-content-po-numbers {
  color: var(--inboundVisibility-po-color);
}

.inbound-visibility-carton, .tooltip-content-cartons {
  color: var(--inboundVisibility-carton-color);
}

.inbound-visibility-unit, .tooltip-content-units {
  color: var(--inboundVisibility-unit-color);
}

.inbound-facet-buttons-container {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 340px;
  height: 45px;
  margin: 10px 0;
  padding-top: 2px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1180px) {
  .inbound-visibility-body {
    flex-direction: column !important;
  }
  .inbound-visibility-facets {
    flex-basis: 300px;
    overflow-x: initial;
    margin-bottom: 60px;
  }

  .facet-group .facet-slide {
    width: 100%;
  }
  .facets-container .facet {
    margin: 0 10px;
  }
  .inbound-visibility-facets  .inbound-visibility-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
  } 
  .activeFlex {
    /* order: -1; */
    /* flex: 1 0 100%; */
  }
  .activeFlex .inbound-visibility-search-toggle  {
    width: min-content;
  }
  .inbound-visibility-facets .facet-label {
    max-width: 150px;
    font-size: 10px;
  }
  .inbound-visibility-container .clear-facets-button {
    position: relative !important;
    top: 70px;
    left: 30px;
    z-index: 1;
    padding: 0px 24px;
  }
}

