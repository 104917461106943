.sim-login-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  overflow: auto;
  padding-bottom: 80px;
}

.sim-login-container .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  margin-top: 80px;
  margin-bottom: 60px;
  flex-shrink: 0;
  flex-grow: 0;
}

.sim-login-container .header .text {
  margin-top: 10px;
}

.sim-login-container form {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
}

.sim-login-container input {
  width: 400px;
  margin-bottom: 8px;
}

.sim-login-container button {
  width: 300px;
  height: 56px;
  margin-top: 16px;
  font-size: 1.5em;
  font-weight: 500;
  font-weight: bold;
  color: #1a1a1a;
  border: 1px solid #666666;
  border-radius: 32px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.sim-login-container-form {
  width: 400px;
}

.sim-login-container .alert {
  margin-top: -20px;
}

.sim-login-container .sim-login-loading {
  width: 30%;
  height: 30px;
  position: relative;
  visibility: hidden;
}

.sim-login-container .sim-login-loading-show {
  width: 30%;
  height: 30px;
  position: relative;
  visibility: visible;
  z-index: 1;
}


.sim-login-container .version-header {
  font-size: 10px;
  color: inherit;
}

.sim-login-container .country-select {
  width: 200px;
  margin-right: 15px;
}

.sim-login-container .store-number-select {
  width: 200px;
}

.sim-login-container .select-label {
  align-self: flex-start;
  margin-left: 8px;
}