.confirmation-modal {
  min-height: 200px;
  min-width: 400px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: hsl(0,0%,100%);
  border: 2px solid hsl(0,0%,80%);
  border-radius: 4px;
  outline: none;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  filter: blur(0);
  padding: 30px 50px;
}

.confirmation-modal-content {
  min-height: 120px;
  min-width: 200px;
  text-align: center;
  margin: auto;
}

.confirmation-btns {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 0 40px;
}

.confirmation-btns button {
  margin: 0 15px;
}

.confirmation-modal-cancel-btn button, .confirmation-modal-submit-btn button {
  border-radius: 32px;
  font-weight: bold;
  height: 40px;
  min-width: 170px;
  background: var(--whiteDefault);
  padding: 0 24px;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
}

.confirmation-modal-submit-btn button {
  color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.confirmation-modal-cancel-btn button {
  color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}

.confirmation-modal-submit-btn:hover button {
  color: var(--whiteDefault);
  background-color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.confirmation-modal-cancel-btn:hover button {
  color: var(--whiteDefault);
  background-color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}