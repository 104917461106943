.scanComplianceWidget_dashboard-card {
  min-width: 500px;
  padding: 14px;
  min-height: 270px;
  width: 100%;
  box-shadow: 0;
  text-decoration: none;
  margin: 10px;
  background-color: var(--widgetBackgroundColor);
  color: var(--whiteDefault);
  border-radius: 20px;
  border: 1.3px solid var(--grey-borders);
  border-radius: 26px !important;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.scanComplianceWidget-buttonOverride {
  background: none;
  border: none;
}

.scanComplianceWidget-buttonOverride button:hover {
  background: #ddd;
}

.scanComplianceWidget_dashboard-card:hover {
  text-decoration: none;
}

.scanComplianceWidget_title {
  margin: 0 25px;
  font-weight: 400;
  font-size: 1.7em;
  line-height: 30px;
}

.scanComplianceWidget_card-loading {
  position: relative;
  top: 70px;
}

.scanComplianceWidget_card-details {
  margin: 0 10px;
}

.scanComplianceWidget_error {
  text-align: center;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 52px;
}

.scanComplianceWidget_date-error {
  margin: auto;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#reactgooglegraph-3, #reactgooglegraph-4 {
  margin-top: 10px;
}

.scanComplianceWidget_weekly-scan-dates {
  display: flex !important;
  flex-direction: row;
  justify-content: space-around;
}

.weekly-scanAccuracy hr {
  display: block;
  border-top: 1.7px solid var(--grey-borders);
  margin: 1em 0; padding: 0;
  padding: 0;
  position: relative;
  top: 40px;
  left: 33px;
  max-width: 610px;
}

.scanComplianceWidget_weekly-scan-single-dates,
.scanComplianceWidget_weekly-scan-single-dates-clickable {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;
}

.scanComplianceWidget_weekly-scan-single-dates p:first-child,
.scanComplianceWidget_weekly-scan-single-dates-clickable p:first-child {
  font-weight: 600;
}

.scanComplianceWidget_weekly-scan-single-dates p:nth-child(2),
.scanComplianceWidget_weekly-scan-single-dates-clickable p:nth-child(2) {
  font-weight: 300;
}

.scanComplianceWidget_weekly-scan-single-dates svg,
.scanComplianceWidget_weekly-scan-single-dates-clickable svg {
  margin-bottom: 5px;
}

.scanComplianceWidget_card-details .scanAccuracy {
  display:flex;
}

.weekly-scanAccuracy .widget-title {
  margin: 8px 0;
  font-weight: 300;
  width: 100%;
  font-size: 1.250em;
  line-height: 30px;
  display: flex;
}

.weekly-scanAccuracy .widget-title p {
  margin: 0;
}

.weekly-scanAccuracy .widget-title svg {
  margin: 7px 10px;
  width: 30px;
}

.scanComplianceWidget_weekly-scan-single-dates .scan-single-dates-values,
.scanComplianceWidget_weekly-scan-single-dates-clickable .scan-single-dates-values,
.scanComplianceWidget_weekly-scan-single-dates-clickable .scan-single-dates-duration {
  text-align: center;
  margin-bottom: 1rem;
}

.scanComplianceWidget_weekly-scan-single-dates-clickable:hover {
  box-shadow: inset 0 0 1px;
  background-color: #343234;
  cursor: pointer;
}

.scanComplianceWidget_weekly-scan-single-dates-clickable:hover .scan-single-dates-values {
  text-decoration: underline;
}

.scanComplianceWidget_legend {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 40px;
}

.scanComplianceWidget_legend p {
  position: relative;
  bottom: 2px;
  padding-right: 8px;
}

.scanComplianceWidget_fullStoreScan, .scanComplianceWidget_salesFloorScan .scanComplianceWidget_offsiteScan {
  font-weight: 500;
  position: relative;
  bottom: 3px;
}

.scanComplianceWidget_salesFloorScan {
  color: var(--salesFloorScan-color2);
}

.scanComplianceWidget_cycleCountScan {
  color: var(--cycleCount-color2);
}

.scanComplianceWidget_fullStoreScan {
  color: var(--fullStoreScan-color);
}

.scanComplianceWidget_offsiteScan {
  color: var(--offsiteScan-color2);
}

.scanComplianceWidget_fullStoreScan_legend {
  background-image: linear-gradient(to bottom, var(--fullStoreScan-color), var(--fullStoreScan-color2));
}

.scanComplianceWidget_offsiteScan_legend {
  background-image: linear-gradient(to bottom, var(--offsiteScan-color), var(--offsiteScan-color2));
}

.scanComplianceWidget_salesFloorScan_legend {
  background-image: linear-gradient(to bottom, var(--salesFloorScan-color), var(--salesFloorScan-color2));
}

.scanComplianceWidget_cycleCount_legend {
  background-image: linear-gradient(to bottom, var(--cycleCount-color), var(--cycleCount-color2));
}

.scanComplianceWidget_legend span {
  height: 14px;
  width: 14px;
  margin: 0 8px;
  border-radius: 2px;
}