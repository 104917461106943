html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

@media print {
  @page {
    size: auto;
  }

  html, body {
    height: auto;
  }

  #root {
    height: auto;
  }
}
