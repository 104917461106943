.chip {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid;
  border-color: #BEBEBE;
}

.color-chips {
  display: flex;
  flex-wrap: wrap;
}

.chip-multiColor {
  background: linear-gradient(130deg, red, orange , yellow, green, cyan, blue, indigo, violet);
  color: #FFF;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.chip:hover {
  border: .13em solid;
  border-color: grey;
}