/* typography */
/*  use for the NEW navbar */
.sim-navMenu-font {
  font-family: 'Helvetica Neue Condensed', Arial, sans-serif;
  font-size: 1.4em;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 38pt;
  color: var(--whiteDefault);
}

.sim-heading-font {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  line-height: 36pt;
  font-size: medium;
}

.sim-heading-large-font {
  font-family: 'Futura', sans-serif;
  src: url('assets/fonts/futura.ttf') format('ttf');
  font-style: 'bold';
  font-size: 38pt;
  line-height: 32pt;
  text-transform: uppercase;
}

.feature-title {
  font-size: 30px;
  font-weight: 500;
  color: var(--blackDefault);
  white-space: nowrap;
}

.feature-subtitle {
  font-size: 1.5em;
  color: #999;
  margin: 6px 0 -6px 0;
  white-space: nowrap;
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

.sim-reports-feature-title {
  font-size: 30px;
  font-weight: 500;
  color: var(--blackDefault);
  white-space: nowrap;
}

/* flexbox stuff */
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: inherit;
  align-items: inherit;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: inherit;
  align-items: inherit;
}

::selection {
  background-color: #24f089;
}

/* border styles */
.borderStyles {
  border: 1.5px solid var(--grey-borders);
  border-radius: 26px !important;
}
