/* productRefillHeader */
.product-refill .feature-header-container {
  padding-bottom: 15px;
}

.product-refill .feature-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product-refill .feature-header .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-refill .feature-header .right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.product-refill .info-box {
  background-color: #eee;
  padding: 15px;
  margin: 20px 0;
}

.product-refill .new-info-box {
  background-color: var(--whiteDefault);
  padding: 15px;
  margin: 0 0 20px;
}

.product-refill .info-box .pair {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 25px;
}

.product-refill .info-box .key {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 11px;
  color: var(--summary-box-label-color);
  text-transform: uppercase;
}

.product-refill .info-box .value {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: var(--summary-box-value-color);
  text-transform: uppercase;
}

.product-refill .new-info-box .pair {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  line-height: 25px;
}

.product-refill .new-info-box .key {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 11px;
  color: var(--summary-box-label-color);
  text-transform: uppercase;
  width: 100px;
}

.product-refill .new-info-box .value {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #1a1a1a;
  text-transform: uppercase;
  text-align: left;
  width: 50px;
}

.product-refill .new-info-box .refreshIcon-container {
  position: relative;
  top: -5px;
}

.product-refill-date {
  font-size: 16px;
  display: flex;
  align-items: baseline;
}

.product-refill-date p {
  position: relative;
  top: 4px;
}

/* productRefillList */
.product-refill .refill-table {
  width: 100%;
}

.product-refill .no-tasks-subtitle {
  margin-left: 30px;
  font-weight: bold;
}

.product-refill .table-item > :first-child {
  padding-left: 30px;
}

/* productRefillTaskDialog */
.mark-as-filled-button {
  justify-items: flex-end;
}