.scan-details-summary-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-evenly;
}

.scan-details-summary-container > * {
  flex: 1 1 auto;
}

.scan-details-summary-container .header-left {
  align-items: start;
  height: 100%;
  justify-content: space-between;
}

.scan-total-value-variance-value-negative {
  background-color: var(--red);
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--whiteDefault);
}

.scan-total-value-variance-value-positive {
  background-color:  #008E98;
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--whiteDefault);
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  
  .MuiDataGrid-main,
  .MuiDataGrid-virtualScroller {
    overflow: visible !important;
    height: auto !important;
  }
}