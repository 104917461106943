.rfid-summary-table-header {
    padding: 4px 56px 4px 13px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    text-align: left;
  }
  
  .rfid-summary-table-header-disabled {
    cursor: default;
  }
  
  .rfid-summary-table-header-selected {
    color: rgba(0, 0, 0, 1);
  }

  .rfid-summary-table-header-fixedHeader {
    position: sticky;
    top: 0px;
    z-index: 100;
    background-color: #fff;
  }

  .rfid-label {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  
  .rfid-label-values {
    white-space: pre;
  }