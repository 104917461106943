.header,
.physical-inventory-upload-file-summary {
  padding-bottom: 15px;
}

.physical-inventory-upload-file-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1600px;
}

.physical-inventory-upload-file-summary .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 240px;  
}

.physical-inventory-upload-file-summary .middle {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  width: 100%;
}

.physical-inventory-upload-file-summary .uploader {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 50px;
  width: 100%;
  min-height: 325px;
  overflow: auto;
}

.physical-inventory-upload-file-summary .uploadMessage {
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  justify-content: center;
  position: relative;
  top: 50px;
  padding-right: 200px;
}

.physical-inventory-upload-file-summary .previewContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  text-align: center;
  align-items: center;
}

.physical-inventory-upload-file-summary .cancel {
  cursor: pointer;
  padding-left: 10px;
}

.physical-inventory-upload-file-summary .modalContainer {
  min-height: 200px;
  min-width: 400px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: hsl(0,0%,100%);
  border: 2px solid hsl(0,0%,80%);
  border-radius: 4px;
  outline: none;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  filter: blur(0);
  padding: 30px 50px;
}

.physical-inventory-upload-summary-box {
  background-color: var(--whiteDefault);
  padding: 15px;
  margin: 0 0 20px;
  min-Height: 325px;
}

.physical-inventory-upload-summary-box .pair {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 25px;
}

.physical-inventory-upload-summary-box .key {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 11px;
  color: var(--summary-box-label-color);
  text-transform: uppercase;
  padding-right: 20px;
}

.physical-inventory-upload-summary-box .value {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: var(--summary-box-value-color);
  text-transform: uppercase;
}

.upload-status {
  font-size: 1em;
  height: 35px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.physical-inventory-upload-button-wrapper {
  display: flex;
  justify-content: center;
  padding-right: 150px;
  position: relative;
  bottom: -25px;
}

.physical-inventory-upload-button-wrapper button {
  margin-right: 60px;
  font-size: 1em;
  min-width: 100px;
  border-radius: 32px;
  font-weight: 700;
  height: 32px;
  background: var(---whiteDefault);
  padding: 0;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
}

.physical-inventory-upload-submit-button {
  color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.physical-inventory-upload-submit-button:hover {
  color: var(--whiteDefault);
  background-color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.physical-inventory-upload-cancel-button {
  color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}

.physical-inventory-upload-cancel-button:hover {
  color: var(--whiteDefault);
  background-color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}

.physical-inventory-upload-view-button:hover {
  color: var(--whiteDefault);
  background-color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.physical-inventory-upload-submit-button:disabled, .physical-inventory-upload-cancel-button:disabled {
  color:var(--grey-disabled-button-color);
  background-color: var(--grey-disabled-button-borders);
  border: 1px solid var(--grey-disabled-button-borders);
}

.physcial-inventory-upload-loading-spinner .loadingIndicator {
  position: static;
  height: 160px;
}

.loadingIndicator {
  height: 50px;
  position: static;
  visibility: visible;
  top: 100px;
  left: 0px;
}

@media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: portrait) {
  .physical-inventory-upload-file-summary {
    width: 1000px;
  }
}


.negative-value-variance-value {
  background-color: var(--red);
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--whiteDefault);
}

.positive-value-variance-value {
  background-color: var(--green);
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--whiteDefault);
}