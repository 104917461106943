.date-range-options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}

.date-range-options-container .date-range-submit-button {
  margin-left: 10px;
  padding-bottom: 1px;
}

.date-range-options-container .label {
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.6875em;
  color: #999;
  letter-spacing: 0.02em;
  font-weight: normal;
  margin-left: 1px;
}

.date-range-options-container .DateRangePicker {
  z-index: 1000;
}

.date-range-options-container .options {
  margin-right: 10px;
  position: relative;
}

.date-range-options-container .options select {
  font-family: Helvetica, Arial, sans-serif;
  text-transform: none;
  font-size: 0.8125em;
  color: #1A1A1A;
  width: 135px;
  height: 34px;
  border: 2px solid #EEE;
  border-radius: 0;
  background: #FFF;
  padding-left: 8px;
  -webkit-appearance: none;
  outline: none;
  margin: 0;
}

.date-range-options-container .options svg {
  position: absolute;
  top: 29px;
  right: 10px;
  width: 10px;
  pointer-events: none;
}

.date-range-options-container .date img {
  z-index: 1;
  pointer-events: none;
  width: 15px;
  height: 15px;
  margin: 0 0 0 -22px;
}

.date-pickers-container {
  display: flex;
}

.date-range-options-warning-message {
  margin-left: 10px;
  font-size: 10px;
  color: var(--red);
}

/* Overrides for AirBNB react-dates element */
.CalendarDay__selected_span,.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  background-color: #555;
  border: 1px double #111;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  color: #ddd;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover,
.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  background-color: #222;
  color: #fff;
  border: 1px double #111;
}

.DateInput_input__focused {
  border-bottom: 2px solid #222;
}

/* Overrides for default browser elements */
.date-range-options-container ::-webkit-inner-spin-button {
  display: none;
}

.date-range-options-container ::-webkit-calendar-picker-indicator {
  color: transparent;
}

.date-range-options-container ::-webkit-calendar-picker-indicator:hover {
  background: none;
}
