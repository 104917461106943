.missing-scan-report .feature-title {
  margin-top: 15px;
}

.missing-scan-report .page-controls {
  margin-left: 50px;
  flex-grow: 1;
  justify-content: space-between;
}

.loading-missing-scans {
  height: 400px;
}

.spinner svg {
  z-index: 1;
  top: 100px;
}

.missing-scan-table {
  position: sticky;
  margin-top:20px;
}

#simple-dialog-title {
  margin-top: 20px;
  text-align: center;
}

.dialog-spinner-content { 
  height: 300px;
  width: 400px;
}

.loadingIndicator {
  height: 0;
}

.missingStyles-dialog-errorContainer {
  margin: 30px;
}
