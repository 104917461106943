.features, .navItem-title-container, .navItem-group-container {
  min-height: 60px;
  align-items: baseline;
}

.navItem-list {
  display: none;
  flex-direction: column;
  border-left: 1px solid var(--grey-borders);
  padding-left: 20px;
  font-size: 12px;
  line-height: 2.5;
  font-weight: light;
}

.features:hover .navItem-list {
  display: flex;
  align-items: flex-start;
}

.features:hover .navItem-clicked {  
  display: none;
}

@media only screen and (max-width: 1122px) {
  .navItem-list {
    padding-left: 15px !important;
  }
}