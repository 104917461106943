.inbound-visibility-body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.inbound-visibility-table {
  flex-grow: 10;
  margin-left: 10px;
  margin-top: 40px;
}

.date-picker-block {
  position: relative;
  left: 60px;
  top: 5px;
}

.inbound-visibility-container .header .feature-title {
  margin-top: 10px;
}

.poDialog-loading-container {
  height: 200px;
}

.poDialog-loading {
  display: inherit;
}

.inbound-description-container {
  border-radius: 7px;
  padding: 20px;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.inbound-description-container .inbound-description-title {
  display: flex;
  align-items: flex-start;
  width: 265px;
  justify-content: space-between;
  padding-bottom: 20px;
  color: var(--inboundVisibility-po-color);
}

.inbound-description-container .inbound-description-title h4 {
  font-family: 'Futura', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.75em;
}

.inbound-description-container .inbound-description-text p {
  font-size: 1.2em;
}