.receiving-log-receive-shipment-modal {
  min-height: 200px;
  min-width: 400px;
  align-items: center;
  justify-content: center;
  background-color: hsl(0,0%,100%);
  border: 2px solid hsl(0,0%,80%);
  border-radius: 4px;
  outline: none;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  filter: blur(0);
  padding: 30px 50px;
}

.receive-shipment-storeArea-dropdown {
  width: 250px;
}

.receive-shipment-storeArea-dropdown .custom-select-label {
  align-self: flex-start;
}

.receive-shipment-input-content {
  display: flex;
  margin-bottom: 100px;
}

.receive-shipment-success-content, .receive-shipment-error-content {
  text-align: center;
  margin-bottom: 100px;
}

.receive-shipment-input-content .scan-button {
  margin-top: 22px;
}

.receive-shipment-buttons {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 0 40px;
}

.receive-shipment-buttons button {
  margin: 0 15px;
}

.receive-shipment-submit-button button, .receive-shipment-cancel-button button, .receive-shipment-ok-button button {
  border-radius: 32px;
  font-weight: bold;
  height: 40px;
  min-width: 170px;
  background: var(--whiteDefault);
  padding: 0 24px;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
}

.receive-shipment-submit-button button, .receive-shipment-ok-button button {
  color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.receive-shipment-cancel-button button {
  color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}

.receive-shipment-submit-button:hover button, .receive-shipment-ok-button:hover button {
  color: var(--whiteDefault);
  background-color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.receive-shipment-cancel-button:hover button {
  color: var(--whiteDefault);
  background-color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}