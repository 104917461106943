.oos-audits-page .feature-title {
  margin-top: 17px;
}

.oos-audits-page .page-controls {
  margin-left: 50px;
  flex-grow: 1;
  justify-content: space-between;
}

.oos-audits-page .page-controls .print-button-block  {
  justify-content: center;
}

.oos-audits-page .page-controls .print-button-block .flex-row {
  align-items: center;
}

.oos-audits-page .page-controls .print-button-block .button {
  margin-right: 15px;
}

.iPad .oos-audits-page .page-controls .print-button-block {
  display: none;
}

.oos-tracking-table {
  margin-top: 20px;
}
