.manager-approval-container {
  padding: 10px;
  margin: 10px 10px 20px 10px;
  display: flex;
  flex-direction: column;
}

.manager-approval-title {
  font-weight: 500;
}

.manager-approval-status{
  border-radius: 10px;
  color: var(--whiteDefault);
  text-align: center;
  padding: 5px 0;
  margin: 10px 0 0 5px;
}

.manager-approval-status-submitted {
  background-color: var(--green-pass);
 }

.manager-approval-status-review {
  background-color: var(--orange);
 }

.scan-report-progressbar {
  margin: 20px 0 0 5px;
}

.manager-approval-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: inherit;
  justify-content: space-evenly;
  margin-top: 20px;
}

.manager-approval-button-title {
  font-size: 1em;
  color: var(--grey-disabled1);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 10px;
  margin-top: 15px;
}

.manager-approval-buttons-wrapper button {
  margin: 0 5px;
  font-size: 1em;
  min-width: 95px;
}

.scan-report-cancel-button, .scan-report-submit-button, .scan-report-resume-button, .clear-button-container button {
  border-radius: 32px;
  font-weight: bold;
  height: 32px;
  background: var(--whiteDefault);
  padding: 0;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
}

.scan-report-resume-button {
  color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.scan-report-submit-button {
  color: var(--scanReport-blue-color);
  border: 1px solid var(--scanReport-blue-color);
}

.scan-report-cancel-button {
  color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}

.scan-report-resume-button:hover, .clear-button-container button:hover {
  color: var(--whiteDefault);
  background-color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.scan-report-submit-button:hover {
  color: var(--whiteDefault);
  background-color: var(--scanReport-blue-color);
  border: 1px solid var(--scanReport-blue-color);
}

.scan-report-cancel-button:hover {
  color: var(--whiteDefault);
  background-color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}

.scan-report-cancel-button:disabled, .scan-report-submit-button:disabled, .scan-report-resume-button:disabled {
  color:var(--grey-disabled-button-color);
  background-color: var(--grey-disabled-button-borders);
  border: 1px solid var(--grey-disabled-button-borders);
}