:root {
  /* default colors */
  --blackDefault:#000000;
  --aqua:#24CEAA;
  --green-success:#ABD648;
  --green-pass: #24f089;
  --grey-disabled1:#999a99;
  --grey-disabled2:#cccccc;
  --grey-borders:#434244;
  --orange:#FF7F00;
  --red: #d32f2f; 
  --red-error:#F45119;
  --red-cancel:#bb1426;
  --red-fail: #f71c2d;
  --whiteDefault:#ffffff;


  /* buttons */
  --grey-disabled-button-color:#525050;
  --grey-disabled-button-borders:#bfbdbd;

  /* dashboard & summary dv colors */
  --widgetBackgroundColor:#292829;
  --widgetContainerBackgroundColor:#343234;

  --apparel-color:#4B8AED;
  --apparel-color2:#2BA2AF;
  --equipment-color:#FFD433;
  --equipment-color2:#FFA917;
  --footwear-color:#F45119;
  --footwear-color2:#FA8936;

  --binAudit_obsolete-color1:#24CEAA;
  --binAudit_obsolete-color2:#2BA2AF;
  --oosAudit_mismates-color1:#4265F5;
  --oosAudit_mismates-color2:#6664FF;
  --obsoleteDonate-color1:#FF55A5;
  --obsoleteDonate-color2:#CB3760;
  --receivingShortage-color1:#EC2A29;
  --receivingShortage-color2:#BB3B4D;

  --fullStoreScan-color:#24CEAA;
  --fullStoreScan-color2:#2BA2AF;
  --offsiteScan-color:#FF55A5;
  --offsiteScan-color2:#CB3760;
  --salesFloorScan-color:#6664FF;
  --salesFloorScan-color2:#4265F5;
  --cycleCount-color: #FF9500;
  --cycleCount-color2: #FF7F00;

  --RFID-accuracy-color:#8AA58F;
  --RFID-expected-color:#17a2b8;
  --RFID-extra-color:#d0b487;
  --RFID-missing-color:#c05850;
  --RFID-scanned-color:#3f51b5;
  --RFID-total-soh-color: #9E3500;

  --sim-voltGreeen-color:#71ea93;

  --summary-box-label-color: #666666;
  --summary-box-value-color: #1A1A1A;

 /* Inbound Visibility Data Viz Colors */
  --genderAge_Mens:#ffc49b;
  --genderAge_Mens2:#DD6E42;
  --genderAge_Womens:#f7be54;
  --genderAge_Womens2:#ffa600;
  --genderAge_Kids:#8781b5;
  --genderAge_Kids2:#6450fa;
  --genderAge_GirlsBigKid:#C0D6DF;
  --genderAge_GirlsBigKid2:#77A8BB;
  --genderAge_AdultUnisex:#EAEAEA;
  --genderAge_AdultUnisex2:#ADADAD;
  --genderAge_BoysInfantToddler:#372698;
  --genderAge_BoysInfantToddler2:#523fbe;
  --genderAge_BoysLittleKid:#4aa443;
  --genderAge_BoysLittleKid2:#63ba6b;
  --genderAge_UnisexInfantToddler:#E3A47A;
  --genderAge_UnisexInfantToddler2:#E6BF96;
  --genderAge_UnisexLittleKid:#677e35;
  --genderAge_UnisexLittleKid2:#8ea86e;
  --genderAge_UnisexBigKid:#937d34;
  --genderAge_UnisexBigKid2:#af9f4b;
  --genderAge_GirlsInfantToddler:#DE4374;
  --genderAge_GirlsInfantToddler2:#e37b8F;
  --genderAge_GirlsLittleKid:#702E6A;
  --genderAge_GirlsLittleKid2:#8F4872;
  --genderAge_Other:#003f5c;
  --genderAge_Other2:#3e687a;

  --retailCategory_Sportswear:#edba5a;
  --retailCategory_Sportswear2:#e09404;
  --retailCategory_Running:#3366d6;
  --retailCategory_Running2:#85a8f2;
  --retailCategory_YoungAthletes:#4D4E2C;
  --retailCategory_YoungAthletes2:#67683B;
  --retailCategory_WomensTraining:#e3e0a8;
  --retailCategory_WomensTraining2:#f5ec45;
  --retailCategory_MensTraining:#6B8F9E; 
  --retailCategory_MensTraining2:#003f5c;
  --retailCategory_Jordan: #ff6361;
  --retailCategory_Jordan2:#ba231e;
  --retailCategory_Basketball:#32a852;
  --retailCategory_Basketball2:#5ce080;
  --retailCategory_Golf:#58508d;
  --retailCategory_Golf2:#bf77b0;
  --retailCategory_FootballSoccer:#2A9D8F;
  --retailCategory_FootballSoccer2:#228176;
  --retailCategory_ActionSports:#bd88a8;
  --retailCategory_ActionSports2:#bc5090;
  --retailCategory_AmericanFootball:#7A0000;
  --retailCategory_AmericanFootball2:#520000;
  --retailCategory_Tennis:#D5A220;
  --retailCategory_Tennis2:#B1871B;
  --retailCategory_BaseballSoftball:#fcba8b;
  --retailCategory_BaseballSoftball2:#f79148;
  --retailCategory_Collegiate:#58508d;
  --retailCategory_Collegiate2:#938cc6;
  --retailCategory_Other:#70484C;
  --retailCategory_Other2:#57383B;
  --retailCategory_Converse:#ed6d37;
  --retailCategory_Converse2:#e38b5f;
  --retailCategory_Hurley:#52652A;
  --retailCategory_Hurley2:#75903C;

  --processingType_Replenishment:#FFBF47;
  --processingType_Replenishment2:#FFA90A;
  --processingType_LoadIn:#cd4631;
  --processingType_LoadIn2:#B53E2C;

  /* Inbound Visibility Facets colors */
  --inboundVisibility-po-color: #2d74a3;
  --inboundVisibility-carton-color: #b45847ef;
  --inboundVisibility-unit-color: #389738;

  /* Scan Report colors */
  --scanReport-blue-color: #3F51B5;
  --scanReport-confirmation-disabled-color: #EEEEEE;
  --scanReport-confirmation-disabled-background: #bab8b8;
}

@font-face {
  font-family: 'Futura', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: local('Futura'), local('Futura'),
    url('./assets/fonts/futuraNike.ttf') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/futuraNike.ttf') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/futuraNike.ttf') format('woff'), /* Modern Browsers */
    url('./assets/fonts/futuraNike.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Futura Condensed', sans-serif;
  font-style: normal;
  font-weight: 600;
  src: local('Futura Condensed Medium'), local('Futura Condensed Medium'),
    url('./assets/fonts/Futura.ttc') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/Futura.ttc') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/Futura.ttc') format('woff'), /* Modern Browsers */
    url('./assets/fonts/Futura.ttc') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: local('Helvetica Neue'), local('Helvetica Neue'),
    url('./assets/fonts/HelveticaNeue.ttf') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/HelveticaNeue.ttf') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/HelveticaNeue.ttf') format('woff'), /* Modern Browsers */
    url('./assets/fonts/HelveticaNeue.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Helvetica Neue Condensed Bold', sans-serif;
  font-style: normal;
  src: local('Helvetica Neue Condensed Bold'), local('Helvetica Neue Condensed Bold'),
    url('./assets/fonts/HelveticaNeue.ttc') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/HelveticaNeue.ttc') format('woff2'), Super Modern Browsers
    url('./assets/fonts/HelveticaNeue.ttc') format('woff'), /* Modern Browsers */
    url('./assets/fonts/HelveticaNeue.ttc') format('truetype'), /* Safari, Android, iOS */
}

.sim-container {
  height: 100vh;
  width: 100%;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: regular; 
  padding-top: 60px;
}

.sim-container .iPad-portrait-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  z-index: 9001;
  font-size: 3em;
  padding: 20%;
  display: none;
}

.sim-container .iPad-portrait-overlay svg {
  width: 72px;
  height: 72px;
  margin-bottom: 72px;
}

.sim-container.iPad.portrait .iPad-portrait-overlay {
  display: flex;
}

.sim-container .sim-content {
  color: var(--blackDefault);
  width: 100%;
  padding: 0 30px 30px 30px;
}

.sim-container .sim-content {
  height: 100%;
}

.reducedAnimation * {
  transition: none !important;
}

.hide {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

button:focus {
  outline: none !important;
}

p {
  letter-spacing: .5px;
}

.widget {
  border-radius: 20px !important;
  background-color: var(--widgetBackgroundColor) !important;
  color: var(--whiteDefault);
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

@media print {
  .sim-container, .sim-container.isLoggedIn {
    padding: 0;
  }

  .sim-container .sim-content {
    overflow-y: visible;
  }
}

.link-disabled {
  pointer-events: stroke;
}

@media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: portrait) {
  .mobile {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 97vh !important;
    height: 100vw !important;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.MuiToolbar-root-MuiTablePagination-toolbar, .MuiTablePagination-toolbar  {
  display: flex !important;
  align-items: baseline !important;
  padding-top: 8px;
}

.MuiTablePagination-root {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}