.dashboardWidgetContainer {
  max-width: 775px;
  box-shadow: 0 8px 40px -12px #0000004d;
  background-color: var(--widgetContainerBackgroundColor);
  border: 1.4px solid #21211f;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.dashboardWidgetContainer_title {
  padding: 20px 0px 10px 0px;
  margin: 0;
  color: var(--whiteDefault);
  font-family: 'Futura', sans-serif;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 2.5em;
}

.dashboardWidgetContainer_children {
  max-width: 850px;
  display: flex;
  top: 40px;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin: 0 20px;
}
