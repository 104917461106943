.markdown--summary-widget {
  display: block;
  padding: 20px;
  height: 270px;
  border-radius: 20px;
  text-decoration: none;
  margin: 0 0 15px 0;
  background-color: var(--widgetBackgroundColor) !important;
  color: var(--whiteDefault);
  justify-content:center;
  align-items:center;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.markdowns-widget-total-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.markdowns-widget-totals-table.tutorial {
  min-height: 300px;
}

.markdowns-widget-totals-table .summary-widget-data {
  grid-template-columns: 300px 60px;
}

.markdowns-widget-totals-table .summary-widget-data-value.percentMarkdownsCompleted {
  margin-bottom: 40px;
}