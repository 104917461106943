.dashboard-page {
  align-items: center;
}

.dashboard-page .dashboard {
  margin-top: 25px;
}

.dashboard-page .dashboard .flex-row {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.dashboard-page .dashboard .dashboard-container div a {
  padding: 0;
}

.dashboard-page .dashboard > div {
  margin-bottom: 20px;
}

.scanComplianceWidget-container,
.scanAccuracyWidget-container,
.scanTimerWidget-container,
.styleComplianceWidget_dashboard_container,
.sizeComplianceWidget_dashboard_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.styleComplianceWidget_dashboard_container,
.sizeComplianceWidget_dashboard_container {
  align-items: start;
}

.scanTimerWidget-header,
.scanAccuracyWidget-header,
.scanComplianceWidget-header {
  width: 100%;
  display: flex;
}

.scanTimerWidget-header .tutorial-joyride-wrapper,
.scanAccuracyWidget-header .tutorial-joyride-wrapper,
.scanComplianceWidget-header .tutorial-joyride-wrapper {
  width: 40%;
}

.dashboard-page .scanComplianceWidget-container h4, .dashboard-page .scanAccuracyWidget-container h4, .dashboard-page .scanTimerWidget-container h4{
  font-family: 'Futura', sans-serif;
  font-style: normal;
  color: #979698;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
}

.rfid-flex-complianceWidgets {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 100%;
}

.welcome-text {
  margin-left: 85px;
}

.welcome-text h1 {
  font-family: 'Futura', sans-serif;
  text-transform: uppercase;
  line-height: 30px;
  padding-bottom: 2px;
  font-weight: bold;
}

.welcome-text span {
  display: flex;
}

.welcome-text p {
  font-size: 17px;
  font-weight: 300;
}

.dashboard-widget-test {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.agedOrders-card {
  align-self: center;
}

.itemInquiryWidget_dashboard-card {
  min-width: 395px;
}

.dashboard_tutorial_button_placeholder {
  height: 31px;
}

@media only screen and (max-width: 1181px) {
  .productSearchFragment {
    display: flex;
  }

  .agedOrders-card {
    max-width: 775px;
    min-width: 775px;
  }
}

@media only screen and (max-width: 1244px) and (min-width: 1182px) {
  .dashboard-page .dashboard .flex-row{
    flex-wrap: nowrap;
  }
}