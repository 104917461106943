.inbound-visibility-widget-dashboard-card {
  min-height: 200px;
  box-shadow: 0;
  text-decoration: none;
  margin: 0 0 15px 0;
  background-color: var(--widgetBackgroundColor) !important;
  color: var(--whiteDefault);
  border-radius: 20px;
}

.inbound-visibility-widget-dashboard-card a, .inbound-visibility-widget-dashboard-card a:hover {
  color: var(--widgetBackgroundColor);
}

.inbound-visibility-widget-title {
  color: var(--whiteDefault);
  font-family: 'Futura', sans-serif;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 1.7em;
}

.inbound-visibility-widget-dates-navigation {
  display: flex;
  margin: 0 10px;
  font-weight: 300;
  width: 100%;
  font-size: 1.250em;
  line-height: 30px;
  color: var(--whiteDefault);
}

.inbound-visibility-widget-dates-navigation p {
  margin: 0;
}

.inbound-visibility-widget-previous-date, .inbound-visibility-widget-next-date {
  background: none;
  border: none;
}

.inbound-visibility-widget-card-loading {
  position: relative;
  bottom: 10px;
}

.inbound-visibility-widget-card-error {
  color: var(--whiteDefault);
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inbound-visibility-widget-data {
  color: var(--whiteDefault);
  display: grid;
  grid-template-columns: 175px 60px;
  grid-template-rows: auto;
  padding: 10px 30px;
}

.inbound-visibility-widget-data-value {
  text-align: right;
}

.inbound-visibility-widget-data p {
  margin-bottom: 0;
  position: relative;
  font-size: 1.2em;
}
