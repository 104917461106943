.sim-button {
  font-weight: bold;
  color: var(--blackDefault);
  height: 32px;
  background: #fff;
  border: 1px solid #666;
  border-radius: 32px;
  padding: 0 24px;
  cursor: pointer;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
}

.sim-button:hover {
  border: 1px solid black;
  color: var(--whiteDefault);
  background-color: var(--blackDefault);
}

.sim-button-darkMode, .sim-button-darkMode-hoverIgnore, .sim-button-darkMode-hoverIgnore:hover {
  font-weight: bold;
  height: 32px;
  border-radius: 32px;
  padding: 0 24px;
  cursor: pointer;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
  color: var(--whiteDefault);
  background-color: var(--widgetBackgroundColor);
  border: 1px solid var(--whiteDefault);
}

.sim-button-darkMode:hover {
  color: var(--widgetBackgroundColor) !important; 
  background-color: var(--whiteDefault) !important; 
  border: 1px solid black;
  background-color: var(--blackDefault);
}

.sim-button:disabled, .button.disabled-darkMode {
  background: #fff;
  color: #bbb;
  border-color: #bbb;
}

.button.disabled .sim-button, .button.disabled-darkMode .sim-button-darkMode, .sim-button-darkMode-hoverIgnore:hover, .sim-button-darkMode-hoverIgnore:hover {
  cursor: not-allowed;
}

.sim-button-darkMode-hoverIgnore:hover {
  fill: black;
}

.button.loading {
  display: flex;
}

.button.loading .sim-button {
  margin-right: 50px;
  cursor: progress;
}

.button svg.lds-spinner {
  height: 50px;
  width: 50px;
  margin-left: -50px;
  margin-top: -9px;
  margin-bottom: -9px;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 250ms;
  display: none;
}

.button.loading svg.lds-spinner {
  width: 50px;
  opacity: 1;
  display: inline-block;
  left: 29.5%;
}

@media print {
  .button {
    display: none;
  }
}

.button.selected button {
  color: #fff;
  background: var(--blackDefault);
}

.button.unselected button {
  color: var(--blackDefault);
  background: #fff;
}

.circleButton {
  display:block;
  height: 300px;
  width: 300px;
  border-radius: 50%;
}