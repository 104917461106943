.scan-details-confirmation-modal-container {
  padding: 50px;
  text-align: center;
}

.scan-details-confirmation-modal-title {
  padding: 0 40px;
  font-size: 24px;
}

.scan-details-confirmation-modal-content {
  text-align: center;
  margin: auto;
  font-size: 24px;
}

.confirmation-buttons {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 0 40px;
  margin-top: 50px;
}

.confirmation-buttons button {
  margin: 0 10px;
}

.confirmation-modal-close-button,
.confirmation-modal-resume-button,
.confirmation-modal-submit-button,
.confirmation-modal-ok-button,
.confirmation-modal-no-button,
.confirmation-modal-yes-button {
  border-radius: 32px;
  font-weight: bold;
  height: 40px;
  min-width: 170px;
  background: var(--whiteDefault);
  padding: 0 24px;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
}

.confirmation-modal-close-button,
.confirmation-modal-resume-button,
.confirmation-modal-submit-button,
.confirmation-modal-ok-button,
.confirmation-modal-no-button,
.confirmation-modal-yes-button {
  color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.confirmation-modal-close-button {
  color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}

.confirmation-modal-submit-button:hover,
.confirmation-modal-resume-button:hover,
.confirmation-modal-ok-button:hover,
.confirmation-modal-no-button:hover,
.confirmation-modal-yes-button:hover {
  color: var(--whiteDefault);
  background-color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.confirmation-modal-close-button:hover {
  color: var(--whiteDefault);
  background-color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}

.confirmation-modal-submit-button:disabled,
.confirmation-modal-resume-button:disabled,
.confirmation-modal-yes-button:disabled {
  color: var(--scanReport-confirmation-disabled-color);
  background-color: var(--scanReport-confirmation-disabled-background);
  border: 1px solid var(--scanReport-confirmation-disabled-background);
}
