.product-image-card-container {
  max-height: 98%;
  margin: 8px;
  padding: 8px;
  flex-grow: 0;
  max-width: 33.333333%;
  align-self: center;
}

.product-image-card {
  display:flex;
  margin-right: 20px;
  padding: 2px !important;
  align-items: center;
  text-align: left;
}

.product-image-card-image {
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.product-image-card-image {
  display: block;
  margin-right: 20px;
}

.product-image-card-text-container {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  margin-top: 17px !important;
  font-size: 0.8125rem;
}

.variances-details-gtin-totals span {
  margin-left: 5px;
}

.variance-product-bins {
  margin-bottom: 1rem;
}

.variance-product-bins p {
  line-height: 6px;
}

@media only screen and (max-width: 1360px) {
  .product-image-card-container {
    align-self: center;
    margin-right: 90px;
  }
  .product-image-card-container > div:first-child {
    width: 300px !important;
  }
  .product-image-card {
    flex-direction: row !important;
  }
  .product-image-card-image {
    margin: 0 20px !important;
    max-width: 50% !important;
    max-height: 50% !important;
  }
  .product-image-card-text-container {
    text-align: left !important;
  }
}

@media only screen and (max-width: 1850px) and (min-width: 1381px) {
  .product-image-card-container {
    margin-right: 20px;
  }
}