.report-picklist-form-container {
  padding: 20px 50px;
  text-align: center;
  width: 700px;
}

.report-picklist-form-title {
  font-size: 32px;
}

.report-picklist-form-input {
  font-size: 32px;
  text-align: center;
  width: 80%;
  border: none;
  border-bottom: 1px solid #ccc;
}

.report-picklist-form-units {
  font-size: 26px;
  padding-top: 20px;
}

.report-picklist-form-buttons {
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
}

.report-picklist-form-buttons .sim-button {
  width: 150px;
  height: 50px;
}

.report-picklist-form-buttons .cancel-button {
  margin-left: 50px;
  color: var(--red-cancel);
  border-color: var(--red-cancel);
}

.report-picklist-form-buttons .cancel-button:hover {
  color:var(--white);
  border-color: var(--red-cancel);
  background-color: var(--red-cancel);
}

.report-picklist-form-buttons .submit-button {
  margin-right: 50px;
}

.report-picklist-form-buttons .submit-button.loading {
  margin-right: 0;
}