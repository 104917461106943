.receiving-log .feature-title {
  margin-top: 15px;
}

.receiving-log .page-controls {
  margin-left: 50px;
  flex-grow: 1;
  justify-content: space-between;
}

.receiving-log .summary {
  margin-bottom: 15px;
  margin-top: 20px;
  justify-content: space-between;
}

.receiving-log .summary .print-button-block {
  justify-content: flex-end;
}

.iPad .receiving-log .summary .print-button-block {
  display: none;
}

.receiving-log .summary .generic-header-container .generic-header-table .loadingIndicator svg {
  width: 400px;
  height: 69px;
}

.receiving-log .summary .receive-shipment-button {
  margin-top: auto;
}

.receiving-log .content {
  margin-bottom: 20px;
  width: 100%;
}

.receiving-log .content .detail td, .receiving-log .content .detail th {
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #1a1a1a;
}

.receiving-log .content .nested-rows:hover {
  background-color: #eee;
}

.receiving-log .content .detail thead {
  background-color: #c6c6c6;
  border-top: 1px solid #eee;
}

.receiving-log .content .detail thead th {
  font-size: 0.6875em;
  font-weight: normal;
  padding: 0 16px 0 0;
  text-align: center;
  letter-spacing: 0.02em;
  height: 32px;
}

.receiving-log .content .detail tbody td {
  font-size: 0.8125em;
  padding: 6px 16px 6px 0;
  text-align: center;
  vertical-align: top;
}

.receiving-log .content .detail td {
  border-top: 1px solid #eee;
}

.receiving-log .content .fail {
  color: #f71c2d;
}

.receiving-log .content .bold {
  font-weight: bold;
}

.receiving-log-search {
  display: flex;
  width: 300px;
  margin-top: 8px;
}

.receiving-log-search-icon {
  position: 'absolute';
  right: '97px';
  top: '7px';
  padding: '7px';
}

.receiving-log .content .totals {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.6875em;
  color: #666;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.receiving-log .content .bottom-row {
  border-bottom: 1.5px solid #666;
}

.receiving-log .content .bottom-row td {
  border-top: 1.5px solid #666;
}

.receiving-log .content td.noBorder, .receiving-log .content .bottom-row td.noBorder,
 .receiving-log .content .topRow td {
  border-top: none;
}

.print-source {
  display: none;
  margin-bottom: 20px;  
}

@media print {
  .print-source {
    display: block;
    margin-bottom: 20px;  
  }
  .print-source .dropdown-table-detail .tables .custom-table .price.red::before {
    content: '(';
  }
  .print-source .dropdown-table-detail .tables .custom-table .price.red::after {
    content: ')';
  }
}

.summary-table-container {
  display: block;
  background-color: var(--widgetBackgroundColor);
  color: var(--whiteDefault);
  box-shadow: 0 8px 40px -12px #0000004d;
  font-family: Helvetica, Arial, sans-serif;
  border-radius: 20px;
  padding: 10px 10px 15px 10px;
  text-align: center;
}

.summary-table-container .summary-table-title {
  font-size: 24px;
}

@media screen and (min-width: 320px) and (max-width: 1366px) and (orientation: landscape) {
  .receiving-log .page-controls {
    margin-left: 0;
  }

  .receiving-log .receiving-log-search {
    width: 240px;
  }
}