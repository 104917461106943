.toggle {
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
}

.toggle-vertical {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 20px;
}

.toggle-label {
  color: var(--grey-disabled1);
  margin-right: 10px;
  cursor: pointer;
  white-space: nowrap;
}

.toggle-selected {
  color: var(--blackDefault);
  position: relative;
}

.toggle-selected:after {
  content: '';
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -3px;
  border-width: 0 0 2px;
  border-style: solid;
  color: var(--blackDefault);
}

.darkMode-toggle {
  color: var(--sim-voltGreeen-color);
  border-color: var(--sim-voltGreeen-color);
}
.darkMode-toggle:after {
  color: var(--sim-voltGreeen-color);
  border-color: var(--sim-voltGreeen-color);
}

.toggle-vertical-label {
  color: var(--grey-disabled1);
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  border-left: 1px solid var(--blackDefault);
  padding-left: 5px;
}

.toggle-vertical-selected {
  color: var(--blackDefault);
  border-left: 3px solid var(--blackDefault);
  position: relative;
}

.toggle-vertical-selected:before {
  color: var(--blackDefault);
}

.tutorial-disabled {
  color: var(--grey-disabled1);
  text-transform: uppercase;
  font-size: 10px;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: 0.02em;
  font-weight: normal;
}
