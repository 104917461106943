.scanAccuracyWidget_dashboard-card {
  width: 100%;
  text-decoration: none;
  margin: 10px !important;
  min-height: 190px;
  background-color: var(--widgetBackgroundColor);
  border-radius: 20px;
  border: 1.3px solid var(--grey-borders);
  border-radius: 26px !important;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.scanAccuracyWidget_card-details {
  margin: 0 10px 0 10px;
  display: flex;
}

.cc-scanAccuracy-container, .sf-scanAccuracy-container {
  margin-left: 10px;
}

.fs-scanAccuracy-container, .cc-scanAccuracy-container, .sf-scanAccuracy-container {
  color: var(--whiteDefault);
  padding: 20px 0px 20px 20px;
}

.fs-scanAccuracy-container {
  min-width: 345px;
}

.scanAccuracyWidget_dashboard-widget, .scanAccuracyWidget_dashboard-widget:hover {
  text-decoration: none;
  color: var(--whiteDefault);
}

.fs-scanAccuracy {
  border-right: 1.7px solid var(--grey-borders);
  min-height: 140px;
}

.scanAccuracyWidget_date-error {
  text-align: center;
}

.scanAccuracyWidget_date-error p {
  padding: 20px 0;
}

.scanAccuracyWidget_date-aggregations {
  display:flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 5px 0 0;
  font-weight: 300;
}

.scanAccuracyWidget_date-aggregations div {
  padding: 5px 20px 5px 0px;
}

.scanAccuracyWidget_card-error {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--whiteDefault);
}

.fs-scanAccuracy, .cc-scanAccuracy, .sf-scanAccuracy {
  display: flex;
  flex-direction: row;
  margin-left: 4px;
}

.fs-scanAccuracy .scanAccuracyWidget_date-error,
.cc-scanAccuracy .scanAccuracyWidget_date-error,
.sf-scanAccuracy .scanAccuracyWidget_date-error {
  flex: auto;
  padding: 35px 0 13px 0;
}

.cc-scanAccuracy-text .cc-scanAccuracy-data,
.fs-scanAccuracy-text .fs-scanAccuracy-data,
.sf-scanAccuracy-text .fs-scanAccuracy-data {
  display: flex;
  width: 100%;
}

.scanAccuracy div p:nth-child(2), .salesFloor-details div p:nth-child(2) {
  font-size:	0.750em;
}

.salesFloor-details {
  padding-top: 30px;
}

.scanAccuracyWidget_card-details .scanAccuracy, .scanAccuracyWidget_card-details .salesFloor-details {
  display:flex;
}

.fs-scanAccuracy .widget-title,
.cc-scanAccuracy .widget-title,
.sf-scanAccuracy .widget-title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 30px;
  margin: 0;
  padding-bottom: 5px;
}

.fs-scanAccuracy .scanAccuracy-date,
.fs-scanAccuracy .scanDuration,
.cc-scanAccuracy .scanAccuracy-date,
.cc-scanAccuracy .scanDuration,
.sf-scanAccuracy .scanAccuracy-date,
.sf-scanAccuracy .scanDuration {
  font-weight: bold;
  font-size: 1.1em;
  padding: 10px 0;
}

.scanAccuracyWidget_card-loading {
  position: relative;
  top: 30px;
  left: 70px;
}

.scanAccuracyWidget_card-loading .lds-spinner {
  width: 30%;
}

/* // we're using these in the util func */
.positive-accuracy-total { 
  color: var(--green-success);
  font-weight: 600;
}

.negative-accuracy-total {
  color: var(--red-error);
  font-weight: 600;
}