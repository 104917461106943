.visual-summaries-container {
  align-items: center;
}

.visual-summaries-summary-widget {
  display: flex;
  min-width: 300px;
  min-height: 225px;
  border-radius: 20px;
  text-decoration: none;
  margin: 0 15px 15px 0;
  background-color: var(--widgetBackgroundColor) !important;
  color: var(--whiteDefault);
  justify-content:center;
  align-items:center;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

@media only screen and (max-width: 1080px) {
  .visual-summaries-container {
    flex-direction: column;
  }
}