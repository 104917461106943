.dropdown-table-detail {
  padding-top: 20px;
}

.dropdown-table-detail .dropdown-table-summary-cell.right .label {
  text-align: left;
}

.dropdown-table-detail .dropdown-table-summary-cell.right .value {
  text-align: left;
}

.dropdown-table-detail .dropdown-table-summary-cell.plain {
  width: 10%;
}

.iPad .dropdown-table-detail .dropdown-table-summary-cell.checkbox {
  display: none;
}

.dropdown-table-detail .tables .custom-table:not(:first-child) thead {
  opacity: 0;
  border: 0 none;
  height: 0px;
}

.dropdown-table-detail .tables .custom-table:not(:first-child) thead * {
  margin: 0;
  padding: 0;
  border: 0 none;
  height: 0px;
  line-height: 0px;
}

.dropdown-table-detail .tables .custom-table td {
  width: 5%;
  min-width: 75px;
  text-align: center;
  padding: 6px 16px 6px 0;
  text-transform: uppercase;
}

.dropdown-table-detail .tables .custom-table tr:first-child td {
  border-top: none;
}

.dropdown-table-detail .tables .custom-table td.left {
  text-align: left;
  padding-left: 32px;
}

.dropdown-table-detail .tables .custom-table td.leftFlush {
  text-align: left;
  padding-left: 0;
}

.dropdown-table-detail .tables .custom-table td.tight {
  width: 6%;
}

.dropdown-table-detail .tables .custom-table td.wide {
  width: 20%;
}

.dropdown-table-detail .tables .custom-table thead td {
  height: 32px;
  padding: 0 16px 0 0;
}

.dropdown-table-detail .tables tbody tr {
  border: none;
  font-size: 0.875rem;
}

.dropdown-table-detail .tables tbody td {
  border-top: 1px solid #eee;
}

.dropdown-table-detail .tables tbody .totals td {
  border-top: 1px solid #999;
  font-weight: bold;
  color: #24f089;
  font-size: 0.875rem;
}

.dropdown-table-detail .tables tbody .totals td.red {
  color: #f71c2d;
  font-size: 0.875rem;
}

.dropdown-table-detail .tables .totals .left {
  color: #666;
  font-weight: normal;
  font-size: 0.875rem;
}

.dropdown-table-detail .tables tbody td.noBorder {
  border: none;
}

.dropdown-table-detail .tables td.noBorder {
  column-width: 0;
}

.dropdown-table-detail .tables thead tr {
  height: 32px;
  padding: 0 16px 0 0;
  background: white;
  font-weight: normal;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #1a1a1a;
  letter-spacing: 0.02em;
  border-top: 1px solid white;
  border-bottom: 1px solid #999;
}
