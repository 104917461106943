/* ARROW STYLES */
.svg-arrow {
  opacity: 1;
  overflow: hidden;
  width: 16px;
  height: 16px;
  transition: opacity 200ms
    cubic-bezier(0.4, 0, 0.2, 1)
    0ms, transform 200ms
    cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-left: 4px;
  margin-right: 4px;
}

.svg-arrow-flip {
  transform: rotate(180deg);
}

.refreshIcon:hover {
  transform: rotate(45deg) translate(0%);
}

.refreshIcon-container svg:hover {
  fill: #1976d2;
  transform: rotate(45deg) translate(0%);
}

/* SORT ARROW STYLES */
.arrowSVGDown {
  transform: scaleY(-1);
}

/* BLOB STYLES */
.blob-shape .gradient-blob {
  fill: url(#svg-gradient) #fff;
}

#svg-gradient {
  --color-stop: #7884cc;
  --color-bot: #3f51b5;
}

/* CHEVRON STYLES */
.defaultChevron, .rightChevron {
  width: 20px;
  height: 20px;
}

.defaultChevron path:hover, .rightChevron path:hover {
  fill: #A0A1A1;
  cursor: pointer;
}

.rightChevron {
  transform: scale(-1,1);
}

/* INFO STYLES */
.info_svg:hover {
  cursor: pointer;
}

/* LOADING INDICATOR STYLES */
.loadingIndicator {
  position: relative;
  margin: 0 auto;
}

.loadingIndicator svg {
  position: absolute;
  width: 20%;
  top: 25%;
  left: 40%;
}

/* NO DEVICE STYLES */
.noDevices {
  position: relative;
  margin: 0 auto;
}

.noDevices svg {
  position: absolute;
  width: 20%;
  top: 25%;
  left: 42%;
  fill: #888;
}

/* PLUS STYLES */
.plus_container, .minus_container {
  display: flex;
  align-items: center;
  padding-top: 4px;
}

.plus_container p, .minus_container p {
  font-weight: 400;
  font-size: small;
}

.plus_container p:hover, .minus_container p:hover {
  cursor: pointer;
}

.plus_container-withText:hover, .minus_container-withText:hover {
  cursor: pointer;
  font-weight: 400;
  text-decoration: underline;
}

.svg-circle-plus, .svg-circle-minus {
  width: 20px;
  height: 20px;
  margin-left: -5px;
}

/* STYLES USED IN MULTIPLE SVGS - */
.align-svg svg {
  padding-bottom: 3px;
  margin-right: 5px;
}

@media print {
  .loadingIndicator {
    display: none;
  }

  .lds-spinner {
    display: none;
  }
}