.scanProductDetails_product-detail-card {
  margin-bottom: 10px;
  width: 100%;
}

.scanProductDetails_product-detail-body {
  display:flex;
  width: 100%;
}

.scanProductDetails_product-detail-title {
  color: var(--scanReport-blue-color);
  font-size: 16px;
  font-weight: 700;
  margin: 8px 8px 0 8px;
  padding: 10px;
}

.scanProductDetails_product-detail-soh {
  color: var(--RFID-total-soh-color);
  font-size: 16px;
  font-weight: 700;
  margin: 0 8px 0 8px;
  padding: 10px;
}

.scanProductDetails_product-detail-title svg {
  margin-top: 5px;
}

.scanProductDetails_details-header {
  display: flex;
  justify-content: space-between;
}

.scanProductDetails_details-gtin-totals {
  display: flex;
}

.scanProductDetails_details-gtin-totals p {
  font-weight: 600;
  padding: 3px;
  margin: 1px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-bottom: 0;
  padding: 5px;
  height: fit-content;
}

.scanProductDetails_details-gtin-totals p span {
  padding-left: 2.5px;
}

.scanProductDetails_details-gtin-totals p:nth-of-type(1) {
  margin-top: 1px !important;
}

.sort-extra-ui-styles {
  color: var(--RFID-extra-color);
  border-bottom: 1.5px solid var(--RFID-extra-color);
}

.sort-missing-ui-styles {
  color: var(--red-cancel);
  border-bottom: 1.5px solid var(--red-cancel);
}

.scanProductDetails_sales-area-buttons {
  display: flex;
  padding-left: 20px;
  height: 20px;
}

.scanProductDetails_sales-area-buttons button {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8px;
}

.scanProductDetails_sales-area-buttons .scanProductDetails_button-wrapper span {
  font-weight: 600;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}

.scanProductDetails_sales-area-buttons button svg {
  -webkit-transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	-moz-transition: all 0.3s;
	-moz-backface-visibility: hidden;
	transition: all 0.3s;
}

.scanProductDetails_sales-area-buttons .scanProductDetails_button-wrapper span {
  display: none;
}

.scanProductDetails_button-wrapper button {
  background: none;
}

.scanProductDetails_sales-area-buttons button:first-of-type:hover svg, .scanProductDetails_sales-area-buttons button:nth-of-type(2):hover svg, .scanProductDetails_sales-area-buttons button:nth-of-type(3):hover svg {
  -webkit-transform: translateY(20%);
	-moz-transform: translateY(20%);
	-ms-transform: translateY(20%);
  transform: translateY(20%);
  padding-bottom: 4px;
}

.scanProductDetails_sales-area-buttons .scanProductDetails_button-wrapper:hover span {
  display: inline-block;
  position:relative;
  top: 5px;
  border-top: 1px solid var(--scanReport-blue-color);
}

#scanTypeSelected {
  color: var(--scanReport-blue-color);
  display: inline-block;
  position:relative;
  top: 5px;
  border-top: 1px solid var(--scanReport-blue-color);
}

.scanProductDetails_sales-area-buttons button:first-of-type, .scanProductDetails_sales-area-buttons button:nth-of-type(2), .scanProductDetails_sales-area-buttons button:nth-of-type(3) {
  background-color: none;
  border: none;
}

.scanProductDetails_sales-area-buttons button:first-of-type svg, .scanProductDetails_sales-area-buttons button:nth-of-type(2) svg, .scanProductDetails_sales-area-buttons button:nth-of-type(3) svg {
  fill: black;
}

@media only screen and (max-width: 1035px) {
  .scanProductDetails_product-detail-card {
    margin: 0 0 10px 0;
  }
  .scanProductDetails_product-detail-body {
    flex-direction: column;
    box-shadow: none !important;
  }
  .scanProductDetails_product-details-table {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 1230px) {
  .scanProductDetails_product-detail-body {
    box-shadow: none !important;
  }
}

@media only screen and (max-width: 1490px) {
  .scanProductDetails_product-detail-body {
    flex-direction: column; 
    width: 620px;
  }
  .scanProductDetails_product-details-table {
    margin: auto !important;
    padding: 10px;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 1080px) {
  .scanProductDetails_product-detail-body {
    width: 620px;
  }

  .scanProductDetails_sales-area-buttons {
    padding-left: 0;
  }
}