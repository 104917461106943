.receivingLogWidget_dashboard-card {
  height: 250px;
  box-shadow: 0;
  text-decoration: none;
  margin: 0 5px 15px 0;
  background-color: var(--widgetBackgroundColor) !important;
  color: var(--whiteDefault);
  border-radius: 20px !important;
}

.receivingLogWidget_dashboard-card .receiving-log-widget-title {
  color: var(--whiteDefault);
  font-family: 'Futura', sans-serif;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 2.5em;
}

.receiving-log-widget-dates-navigation {
  display: flex;
  margin: 0 10px;
  font-weight: 300;
  width: 100%;
  font-size: 1.250em;
  line-height: 30px;
  color: var(--whiteDefault);
}

.receiving-log-widget-dates-navigation p {
  margin: 0;
}

.receiving-log-widget-previous-date, .receiving-log-widget-next-date {
  background: none;
  border: none;
}

.receivingLogWidget_card-loading {
  position: relative;
  bottom: 10px;
}

.receivingLogWidget_card-error {
  color: var(--whiteDefault);
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.receivingLogWidget_card-error p {
  font-weight: 400;
}