.admin-container {
  display: flex;
  justify-content: center; 
  flex-grow: 0;
  flex-shrink: 0;
  flex-wrap: wrap;
  grid-gap: 10px;
}

.admin-container .loadingIndicator svg {
 top: 60%;
}

.config-container, .storeDetails-container {
  min-width: 700px !important;
  min-height: 300px;
  padding: 40px !important;
  border-radius: 20px;
  text-decoration: none;
  background-color: var(--widgetBackgroundColor);
  color: var(--whiteDefault);
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.config-container > h3, .config-container button, .storeDetails-container > h3 {
  margin-left: 14px;
}

.config-container  button {
  margin-bottom: 10px;
}

.admin-config-status-message {
  display: flex;
  align-items: baseline;
  margin-left: 14px;;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
}

.admin-label {
  font-size: 1rem;
  color: var(--grey-disabled1);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-transform: uppercase;
}

.config-container h3, .storeDetails-container h3 {
  margin-bottom: 30px;
  color: var(--whiteDefault);
  font-family: 'Futura', sans-serif;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 2.5em;
}

.storeDetails-container p {
  margin-left: 16px;
  margin-right: -11px;
  margin-bottom: 1.5rem;
}

.admin-label { 
  padding-right: 4px;
}