.adjustments-page .feature-title {
  margin-top: 15px;
}

.adjustments-page .page-controls {
  margin-left: 50px;
  flex-grow: 1;
  justify-content: space-between;
}

.adjustments-page h4 {
  margin: 16px 0;
  line-height: 1em;
}

.adjustments-page .details .price.red::before {
  content: '(';
}

.adjustments-page .details .price.red::after {
  content: ')';
}

.adjustments-page .details .totals td {
  font-weight: bold;
}

.adjustments-page .summary .custom-table td {
  float: left;
  width: 175px;
}

.adjustments-page .details .tables .custom-table td {
  width: 10%;
  text-align: center;
  padding: 6px 16px 6px 0;
  text-transform: uppercase;
}

.adjustments-page .details .tables .custom-table td.left {
  text-align: left;
  padding-left: 32px;
}

.adjustments-page .details .tables .custom-table td.leftFlush {
  text-align: left;
  padding-left: 0;
}

.adjustments-page .details .tables .custom-table td.tight {
  width: 6%;
}

.adjustments-page .details .tables .custom-table td.wide {
  width: 20%;
}

.adjustments-page .summary-table thead td {
  height: 32px;
  padding: 0 16px 0 0;
}

.adjustments-page .details .totals td:first-child {
  color: #666;
  font-weight: normal;
  font-size: 0.8375em;
}

.adjustments-page .details tbody td:first-child {
  border: none;
}

.adjustments-page .details .summary .custom-table tr {
  display: flex;
}
.adjustments-page .details .summary .custom-table td {
  flex: 1 0 1px;
  max-width: 146px;
}

.adjustments-page .summary .dropdown-summary-table .checkbox {
  display: none;
}

.adjustments-page .adjust-type-title:not(:first-of-type) tr:first-of-type {
  display: none;
}

@media print {
  .adjustments-page .header .page-controls {
    display: none;
  }

  .adjustments-page .custom-table {
    break-after: page;
  }

  .adjustments-page .details .custom-table thead {
    display: table-header-group !important;
  }

  .adjustments-page .details .dropdown-table-detail {
    max-height: 100% !important;
  }

  .adjustments-page .print-hidden {
    display: none;
  }
}

.still-loading {
  position: absolute;
  margin-inline: 43px;
  width: 171px;
  margin-top: -30px;
}

.muiSummaryTable {
  margin-bottom: 20px;  
}

.adjustmentDetailsFullReport {
  margin-bottom: 20px;  
}

.adjustments-page .dropdown-table-detail .tables .custom-table .price.red::before {
  content: '(';
}

.adjustments-page .dropdown-table-detail .tables .custom-table .price.red::after {
  content: ')';
}

.adjustments-search {
  display: flex;
  width: 300px;
  margin-top: 8px;
}

.print-source {
  display: none;
  margin-bottom: 20px;  
}

@media print {
  .print-source {
    display: block;
    margin-bottom: 20px;  
  }
  .print-source .dropdown-table-detail .tables .custom-table .price.red::before {
    content: '(';
  }
  .print-source .dropdown-table-detail .tables .custom-table .price.red::after {
    content: ')';
  }
}

.dropdown-table-detail .tables tbody .totals td:first-child {
  color: #666;
  font-weight: 400;
  font-size: .8375em;
}

.adjustments-page .print-option-block {
  top: 15px;
  display: flex;
  flex-direction: row-reverse;
}

.adjustments-page .summary {
  margin-bottom: 15px;
  margin-top: 20px;
  justify-content: space-between;
}

.summary-table-container {
  display: block;
  background-color: var(--widgetBackgroundColor);
  color: var(--whiteDefault);
  box-shadow: 0 8px 40px -12px #0000004d;
  font-family: Helvetica, Arial, sans-serif;
  border-radius: 20px;
  padding: 10px 10px 15px 10px;
  text-align: center;
}

.summary-table-container .summary-table-title {
  font-size: 24px;
}

.summary-table-data-container .adjustment-summary-table {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 1%;
  margin-top: 10px;
  border-radius: 20px;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: none;
  font-size: 14px;
  color: var(--whiteDefault);
  min-height: 100px;
  min-width: 600px;
  background-color: var(--widgetBackgroundColor);

}

.adjustment-summary-table .row {
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 5px;
  width: 100%;
  margin-left: 2px;
}

.adjustment-summary-table .column {
  flex-direction: column;
  flex: 1;
}

.adjustIn-totals {
  color: #24f089;
  font-weight: 'bold';
}

.adjustOut-price {
  color: red;
}

.adjustOut-totals {
  color: red;
  font-weight: 'bold';
}
.adjustment-summary-table .row .column .adjust-type-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
}

.adjustment-summary-table .adjustIn-body {
  border-right: 1px solid var(--whiteDefault)
}

.adjustment-summary-table .adjustOut-body {
  border-left: 1px solid var(--whiteDefault)
}