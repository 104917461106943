.bin-audits-page .feature-title {
  margin-top: 15px;
}

.bin-audits-page .page-controls {
  margin-left: 50px;
  flex-grow: 1;
  justify-content: space-between;
}

.bin-audits-page .page-controls .print-button-block {
  justify-content: flex-end;
}

.bin-audits-page .page-controls .print-button-block .flex-row {
  align-items: center;
}

.bin-audits-page .page-controls .print-button-block .button {
  margin-right: 15px;
}

.iPad .bin-audits-page .page-controls .print-button-block {
  display: none;
}

.dropdown-table-detail .tables .custom-table:not(:first-child) thead {
  opacity: 0;
  border: 0 none;
  height: 0px;
}

.dropdown-table-detail .tables .custom-table:not(:first-child) thead * {
  margin: 0;
  padding: 0;
  border: 0 none;
  height: 0px;
  line-height: 0px;
}

.dropdown-table-detail .tables thead tr {
  background: white;
  border-top: none;
  border-bottom: 1px solid #999;
}

.dropdown-table-detail .tables .custom-table td {
  width: 12%;
  text-align: center;
  padding: 6px 16px 6px 0;
  text-transform: uppercase;
}

.dropdown-table-detail .tables .custom-table tr:first-child td {
  border-top: none;
}

.dropdown-table-detail .tables .custom-table td.last {
  width: 100%;
}

.dropdown-table-detail .tables .custom-table td.left {
  text-align: left;
  padding-left: 32px;
}

.dropdown-table-detail .tables .custom-table thead td {
  height: 32px;
  padding: 0 16px 0 0;
}

.dropdown-table-detail .tables tbody tr {
  border: none;
}

.dropdown-table-detail .tables tbody td {
  border-top: 1px solid #EEE;
}

.dropdown-table-detail .tables tbody td .g72-check, .dropdown-table-detail .tables tbody td.passFail {
  color: var(--green-success);
}

.dropdown-table-detail .tables tbody td .g72-x-thick, .dropdown-table-detail .tables tbody td.passFail.red {
  color: var(--red-error);
}

.dropdown-table-detail .tables tbody td.left:not(.wide) {
  border: none;
}

.bin-audits-table {
  margin-top: 20px;
}

@media print {
  .bin-audits-page .page-controls, .bin-audits-page .feature-subtitle {
    display: none;
  }

  .bin-audits-page .details .tables .custom-table {
    break-inside: avoid-page;
  }
}



