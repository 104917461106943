.header-container {
    display: flex;
}

.header-left {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.gtin-search {
    display: flex;
}
