.scan-report-sidebar {
  padding: 5px;
  grid-area: sidebar;
}

.scan-report-sidebar .dynamic-search-container .label {
  width: 350px;
}

.scan-report-sidebar .dynamic-search-container input {
  width: 295px;
}

.scan-report-scanType .facet-group,
.scan-report-scanType .facet-label {
  letter-spacing: normal;
}

.scan-report-scanType .facet-group-title {
  font-size: 12px;
}

@media only screen and (max-width: 1080px) {
  .scan-report-sidebar .dynamic-search-container .label {
    width: 315px;
  }

  .scan-report-sidebar .dynamic-search-container input {
    width: 255px;
  }
}