.scan-report-manager-approval {
  display: flex;
}

.scan-report-status {
  font-size: 1em;
  color: var(--grey-disabled1);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 10px;
}

.scan-report-progressbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.manager-approval-button-wrapper {
  display: flex;
  align-items: inherit;
}

.manager-approval-button-wrapper p {
  font-size: 1em;
  color: var(--grey-disabled1);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 10px;
  margin-top: 6px !important;
}

.manager-approval-button-wrapper button {
  margin: 0 5px;
  font-size: 1em;
  min-width: 100px;
}

.scan-report-cancel-button, .scan-report-submit-button, .scan-report-resume-button, .clear-button-container button {
  border-radius: 32px;
  font-weight: bold;
  height: 32px;
  background: var(--whiteDefault);
  padding: 0;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
}

.scan-report-resume-button {
  color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.scan-report-submit-button {
  color: var(--scanReport-blue-color);
  border: 1px solid var(--scanReport-blue-color);
}

.scan-report-cancel-button {
  color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}

.scan-report-resume-button:hover, .clear-button-container button:hover {
  color: var(--whiteDefault);
  background-color: var(--blackDefault);
  border: 1px solid var(--blackDefault);
}

.scan-report-submit-button:hover {
  color: var(--whiteDefault);
  background-color: var(--scanReport-blue-color);
  border: 1px solid var(--scanReport-blue-color);
}

.scan-report-cancel-button:hover {
  color: var(--whiteDefault);
  background-color: var(--red-cancel);
  border: 1px solid var(--red-cancel);
}

.scan-report-cancel-button:disabled, .scan-report-submit-button:disabled, .scan-report-resume-button:disabled {
  color:var(--grey-disabled-button-color);
  background-color: var(--grey-disabled-button-borders);
  border: 1px solid var(--grey-disabled-button-borders);
}