.dynamic-search-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}

.dynamic-search-container .label {
  color: var(--grey-disabled1);
  padding-top: 8px;
  margin: 8px 0;
  text-transform: uppercase;  
  padding-bottom: 5px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  height: 27px;
  width: 300px;
}

.dynamic-search-container input {
  font-family: Helvetica, Arial, sans-serif;
  text-transform: none;
  font-size: 0.8125em;
  color: #1A1A1A;
  width: 235px;
  height: 25px;
  background: #FFF;
  -webkit-appearance: none;
  outline: none;
  margin: 0;
}

.dynamic-search-container button:hover {
  color:blue;
}

.dynamic-search-container .label div {
  margin-left: auto;
}