.productCard .productDetails {
  position: relative;
  display: flex;
}

.productCard .productDetails .details {
  margin-right: 12px;
  width: initial;
  overflow-y: auto;
}

.productDetails_catalogDetails_title {
  text-transform: uppercase;
  font-weight: 500;
}

.productCard .productDetails .details .productDescription p, .productCard .productDetails, .details .productDescription ul {
  line-height: 1.05em;
  margin-bottom: 0.4rem;
  font-size: 1.2em;
}

.productCard .productDetails .image {
  margin-right: 12px;
  border: 1px solid black;
  border-radius: 10px;
  overflow: hidden;
}

.productCard .productDetails .image .back, .productCard .productDetails .image .next {
  position: absolute;
  top: 50%;
  background-color: rgba(0, 0, 0);
  border-radius: 30px;
  width: 30px;
  height: 30px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.productCard .productDetails .image:hover .back, .productCard .productDetails .image:hover .next {
  opacity: 0.6;
}

.productCard .productDetails .image .back:disabled, .productCard .productDetails .image .next:disabled {
  opacity: 0 !important;
  cursor: default;
}

.productCard .productDetails .image .back {
  left: 10px;
}

.productCard .productDetails .image .next {
  right: 10px;
}

.productCard_itemPrice {
  display: flex;
  align-items: baseline;
}

.productCard_item_price_details {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1rem;
  flex-wrap: wrap !important;
  justify-content: left;
}

.productCard_item_price_details > span:first-of-type, .productCard_item_price_details > span:nth-of-type(2) {
  border: 1px solid #ebebeb;
  padding: 10px;
  min-width: 170px;
}

.productCard_current-price {
  color: var(--genderAge_BoysLittleKid);
  font-size: 18pt;
}

.productCard_msrp-price {
  font-size: 18pt;
}

.productCard-rfid-label {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.productCard-rfid-label-values {
  white-space: pre;
}

.productCard-summary-table-header {
  padding: 4px 56px 4px 13px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  text-align: left;
}

.productCard-summary-table-header-disabled {
  cursor: default;
}

.productCard-summary-table-header-selected {
  color: rgba(0, 0, 0, 1);
}

.productDetail-noResult .shoebox {
  pointer-events: none;
  display: flex;
  align-items: center;
  padding-top: 140px;
}

.productDetail-noResult svg {
  width: 300px;
  height: 300px;
}

.scan-details-back-button svg, .scan-details-next-button svg {
  fill:white;
}

.scan-details-back-button button:disabled svg,
.scan-details-next-button button:disabled svg,
.scan-details-back-button:hover svg,
.scan-details-next-button:hover svg,
.scan-details-back-button svg:hover path,
.scan-details-next-button svg:hover path {
  fill:black;
}

.scan-details-back-button button, .scan-details-next-button button {
  height: 40px;
  width: 40px;
  text-align: center;
  padding: 0;
}

.scan-details-next-button button svg {
  margin: 9px 10px 0 0;
}

.scan-details-back-button button svg {
  margin: 10px 0 0 9px !important;
}

.scan-details-back-button button:disabled, .scan-details-next-button button:disabled {
  border: rgba(0, 0, 0, .1) !important;
  background-color: rgba(0, 0, 0, .1) !important;
}

.scan-details-back-button button:disabled svg:hover path, .scan-details-next-button button:disabled svg:hover path {
  cursor: not-allowed !important;
  fill: black;
}

.sim-button-darkMode-hoverIgnore, .sim-button-darkMode-hoverIgnore:hover {
  height: 40px;
  width: 300px !important;
  padding: 0 30px;
}

.sim-button-darkMode-hoverIgnore-disabled {
  background-color: #e5e5e5 !important;
  color: black !important;
}

@media only screen and (max-width: 1223px) {
  .productCard_item_price_details > span:first-of-type, .productCard_item_price_details > span:nth-of-type(2) {
    width: 100%;
  }
}