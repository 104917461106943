.report {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.content-details {
  display: grid;
  grid-template-columns: 350px auto;
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
}

.facets {
  margin-top: 38px;
}

.report .title-block {
  align-items: center;
}


.loading-indicator {
  position: relative;
}

.loading-indicator svg {
  top: 50%;
  left: 50%;
}
