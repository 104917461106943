.header-visuals {
  display: flex;
  align-items: center;
  min-height: 345px;
  justify-content: center;
  margin-bottom: 15px;
}
  
.header-graph-table {
  min-width: 300px;
  min-height: 300px;
  border-radius: 20px;
  text-decoration: none;
  margin: 0 15px 15px 0;
  background-color: var(--widgetBackgroundColor) !important;
  color: var(--whiteDefault);
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.header-graph-table .live-badge {
  padding-top: 10px;
  padding-right: 10px;
  margin-bottom: -20px;
}

.header-data-graphs {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  min-width: 650px;
}

.header-data-graphs:first-child {
  padding-bottom: 0;
}
.header-data-graphs:last-of-type {
  padding-top: 0;
}

.header-graph-sub-decription-title {
  text-align: center;
  padding-top: 20px;
  font-size: 1.4em;
}

.visual-summary-loading .loadingIndicator svg {
  position: relative;
  top: 120px;
  width: fit-content;
}

.visual-summary-no-data {
  position: relative;
  top: 110px;
  text-align: center;
}

.visual-summary-no-data p {
  padding-top: 10px;
}

@media only screen and (min-width: 1200px) {
  .header-visuals {
    flex-direction: row;
  }
}
