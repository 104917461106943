.priceChanges-totals-table {
  padding: 0 20px;
  margin-top: 30px;
  height: 270px;
}

.priceChanges-totals-table .summary-widget-data {
  grid-template-columns: 300px 60px;
}

.priceChanges-totals-table .summary-widget-data-value.percentMarkdownsCompleted {
  margin-bottom: 40px;
}

.priceChanges-last-updated-date {
  font-size: 16px;
  align-items: center;
  display: flex;
}

.mardown-progress-percentage {
  margin: 20px;
}