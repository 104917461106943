.store-performance-page .feature-title {
  margin-top: 15px;
}

.store-performance-page .page-controls {
  margin-left: 50px;
}

.store-performance-page .details .totals td {
  font-weight: bold;
}

.store-performance-page .details .dropdown-table-container .custom-table td {
  width: 10%;
}
