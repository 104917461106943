.buttonColumn {
  display: flex;
  grid-gap: 10px;
  min-width: 220px;
  flex-wrap: wrap;
}

.buttonColumn button {
  min-width: 220px;
}

.buttonContainer button {
  width: 100%;
}

.buttonContent {
  display: inline-flex;
  align-items: center;
  color: inherit;
}

.buttonIcon {
  margin-left: 5px;
  background: transparent;
}

.clearanceText {
  font-size: large;
}