.summary-table {
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  margin-top: 10px;
  border-radius: 20px;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: none;
  font-size: 14px;
  color: var(--whiteDefault);
  min-height: 100px;
  max-width: 400px;
  background-color: var(--widgetBackgroundColor);
}

.summary-table .loadingIndicator svg {
  padding: 0;
  margin: 0;
  position: initial;
}


.summary-table .summary-table-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 5px;
  font-size: 14px;
  text-transform: uppercase;
}
.summary-table .summary-table-row:first-child {
  font-weight: bold;
  font-size: 16px;
}

.summary-table .summary-table-row:last-of-type {
  padding-bottom: 0;
}

.summary-table .summary-table-row .object-group {
  display: flex;
  padding: 0 16px 0 16px;
  width: 150px;
  align-items: center;
  justify-content: space-between;
}

.summary-table .negative {
  color: var(--red-error);
}

.summary-table.financial .negative::before {
  content: "(";
}

.summary-table.financial .negative::after {
  content: ")";
}