.agedOrders-dashboard-card {
  max-height: 300px;
  text-decoration: none;
  margin-bottom: 10px;
  background-color: var(--widgetBackgroundColor);
  color: var(--whiteDefault);
  border: 1.3px solid var(--grey-borders);
  border-radius: 26px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    overflow-y: auto;
}

.agedOrders-dashboard-card path {
  stroke: var(--whiteDefault);
}

.agedOrders-widget-details-container {
  margin:10px 25px;
}

.agedOrders-widget-details-container.tutorial {
  margin:10px 25px;
}

.agedOrders-widget-title-container {
  display: flex;
  justify-content: center;
  color: var(--whiteDefault);
  font-family: 'Futura', sans-serif;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 2.5em;
}

.agedOrders-widget-type-title {
  margin: 8px 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}

.agedOrders-widget-type-title p {
  margin: 0;
}

.agedOrders-order-details-bopis {
  padding-right: 15px;
  border-right: 2.5px solid var(--grey-borders);
}

.agedOrders-order-details-sfs {
  padding-left: 15px;
}

.agedOrders-order-details-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.agedOrders-grid-container {

}

.scanAccuracyWidget_dashboard-widget, .scanAccuracyWidget_dashboard-widget:hover {
  text-decoration: none;
  color: var(--whiteDefault);
}

.agedOrders-grid-container-summary table {
  text-align: left;
  min-width: 375px;
}

.agedOrders-grid-container-summary th {
  font-size: 16px;
}

.agedOrders-grid-container-summary td {
  font-size: 14px;
}


.agedOrders-grid-container-tutorial {
  max-height: 94px;
}

.agedOrders-grid {
  font-size: 12px;
  font-weight: 300;
  display: grid;
  grid-template-columns: 100px 125px 70px;
  gap: 10px;
  justify-content: stretch;
  justify-items: center;
}

.agedOrders-grid-column-title {
  border-bottom: 2.5px solid var(--grey-borders);
}

.agedOrders-grid-items{
  display: flex;
  flex-direction: row;
  z-index: -1;
}

.agedOrders-grid-items p {
  font-size: 12px;
  font-weight: 200;
}

.agedOrders-grid-items-high-priority {
  display: flex;
  flex-direction: row;
}

.agedOrders-grid-items-high-priority p {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
  color: var(--red);
  padding-right: 3px;
}

.agedOrders-grid-items-high-priority path {
  stroke: var(--red);
}

.agedOrders-grid-items-medium-priority {
  display: flex;
  flex-direction: row;
}

.agedOrders-grid-items-medium-priority p {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
  color: var(--orange);
  padding-right: 3px;
}

.agedOrders-grid-items-medium-priority path {
  stroke: var(--orange);
}

.agedOrders-grid-items-low-priority {
  display: flex;
  flex-direction: row;
}

.agedOrders-grid-items-low-priority p {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
  color: var(--green);
}

.agedOrders-grid-items-low-priority path {
  stroke: var(--green);
}

.agedOrders-order-details-container-border {
  min-width: 350px;
}

.agedOrders-grid-container::-webkit-scrollbar-track {
  background: var(--widgetContainerBackgroundColor);
}

.agedOrders-grid-container::-webkit-scrollbar-thumb {
  background:#979698;
}

.agedOrders-order-details-container-border hr {
  display: block;
  border-right: 2.5px solid var(--grey-borders);
  max-height: max-content;
  min-width: 375px;
}

.agedOrders-grid-column-title {
  display: flex;
}

.agedOrders-grid-column-title p {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  margin-bottom: 2px;
}

.agedOrders-grid-column-title svg {
  fill: white
}

.agedOrders-status-tooltip {
  padding-left: 5px;
  align-self: center;
}

.agedOrders-widget-loading {
  position: relative;
  top: 40px;
}

.agedOrders-widget-success {
  color: var(--whiteDefault);
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agedOrders-widget-success path {
  stroke: var(--green);
}

.agedOrders-tooltip {
  padding: 10px;
  z-index: -1;
}

.agedOrders-tooltip-icons {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.agedOrders-tooltip-icons svg {
  height: 26px;
  width: 26px;
}

.agedOrders-requested-icon {
  padding-left: 4px;
}

/*TODO: Update classnames based on translation keys */
.agedOrders-tooltip-icons .high_priority {
  color: var(--red);
  padding-right: 10px;
  margin: inherit;
}

.agedOrders-tooltip-icons .medium_priority {
  color: var(--orange);
  padding-right: 10px;
  margin: inherit;
}

.agedOrders-tooltip-icons .low_priority {
  color: var(--aqua);
  padding-right: 10px;
  margin: inherit;
}

.agedOrders-tooltip-icons path{
  stroke: var(--whiteDefault);
}

.agedOrders-tooltip-icons span {
  padding-left: 5px;
}

.agedOrders-label {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.agedOrders-values {
  white-space: pre;
}
