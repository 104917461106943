.scan-progress-tool-tip {
  display: grid;
  grid-template: 1fr / 1fr;
}

.scan-progress-tool-tip > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.scan-progress-tool-tip > div {
  z-index: 1;
}

.scan-progress-tool-tip > h4{
  z-index: 2;
  background-color: transparent;
}