.size-compliance-picklist-modal {
  text-align : center;
  padding-top: 25px;
  padding-bottom: 50px;
  width: 550px
}

.size-compliance-picklist-modal-title {
  font-size: 26px;
}

.size-compliance-picklist-modal-content {
  font-size: 20px;
  color: black;
  font-family: inherit;
  padding-left: 25px;
  padding-right: 25px;
}

.size-compliance-picklist-modal-button {
  width: 150px
}
