.scan-report-data-container {
  margin-left: 30px;  
}

.scan-report-paging-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-right: 10px;
}

.scan-report-sort-options {
  display: flex;
}

.scan-report-sort-by-dropdown {
  flex-direction: row;
  align-items: flex-end;
}

.scan-report-sort-by-dropdown label {
  padding-right: 10px;
}

.scan-report-sort-by-customize-dropdown { 
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.scan-report-pagination {
  grid-area: pagination;
  text-align: center;
  width: 100%;
}

.scan-report-pagination-size-dropdown {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: max-content;
}

.scan-report-customize-pagination-dropdown {
  width: 80px;
  margin-left: 5px;
}

.scan-report-pagination-size-dropdown label {
  margin-left: 10px;
}

.scan-report-sort-by-customize-dropdown > div {
  width: 170px;
}