.custom-select-container {
  display:flex;
  flex-direction: column;
}

.custom-select-label {
  font-size: 12px;
  letter-spacing: 1px;
  align-self: flex-end;
  margin-bottom: 10px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif
}

.custom-select-component { 
  width: 100%;
  padding-left: 5px;
}

.arrowSVGUp {
  padding-bottom: 3px;
  margin-left: 2px;
}

.arrowSVGDown {
  padding-top: 3px;
  margin-left: 2px;
}

.css-26l3qy-menu {
  z-index: -1;
  position: absolute;
}
