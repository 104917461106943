.itemInquiryWidget_dashboard-card {
  min-height: 430px;
  box-shadow: 0;
  text-decoration: none;
  margin: 0 0 15px 0;
  background-color: var(--widgetBackgroundColor) !important;
  color: var(--whiteDefault);
  border-radius: 20px !important;
}

.itemInquiryWidget_dashboard-card a, .itemInquiryWidget_dashboard-card a:hover {
  color: var(--blackDefault);
}


.itemInquiryWidget_dashboard-card input:-webkit-autofill, .itemInquiryWidget_dashboard-card 
input:-webkit-autofill:hover, .itemInquiryWidget_dashboard-card 
input:-webkit-autofill:focus, .itemInquiryWidget_dashboard-card 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0px 1000px #292829 inset;
  -webkit-text-fill-color: #ffffff;
}

.itemInquiryWidget_title {
  color: var(--whiteDefault);
  font-family: 'Futura', sans-serif;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 2.5em;
}

.itemInquiryWidget_dashboard-container {
  color: var(--whiteDefault);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.itemInquiryWidget_dashboard-card-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
}

.itemInquiryWidget_data {
  margin: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center !important;
  grid-gap: 10px;
}

.itemInquiryWidget_data svg, .itemInquiryWidget_data svg path {
  stroke: white;
}

.itemInquiryWidget_data p {
  margin-bottom: 0;
}

.itemInquiryWidget_goButton button {
  white-space: nowrap;
}

#inquiryGoToReportButton {
  margin: 20px;
}

.itemInquiryWidget_card-stateLayout {
  position: relative;
  bottom: 10px;
  top: 30px;
  left: 5px;
  text-align: center;
}

.itemInquiryWidget_card-loading {
  position: relative;
  top: 10px;
}

.itemInquiryWidget_card-error {
  color: var(--whiteDefault);
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50px;
}

.itemInquiryWidget_error {
  color: var(--whiteDefault);
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.itemInquiryWidget_card-loading svg {
  position: initial;
  width: 70%;
}

.productSearchFragment-productDetails-container {
  margin-right: 20px;
}

.productSearchFragment-title {
  font-weight: 600;
}

.productSearchFragment-soh {
  display: inline-block;
}

.rfid-label-values {
  white-space: pre;
}

.image-carousel {
  width: 70%;
  position: relative;
  margin: 0 auto;
}

.productSearchFragment-productDetails {
  display: flex;
  flex-direction: column;
  gap: 10px 10px;
  margin: 0 10px;
}

.productSearchFragment-productDetails div > * {
  margin: 5px 0;
}

.productSearchFragment-productDetails .toggle-label {
  margin-right: 10px;
}

.productSearchFragment .productSearchFragment-name {
  font-weight: 500;
  font-size: 18px;
  margin-left: 10px;
  color: var(--sim-voltGreeen-color);
}

.default-shoebox-state {
  position: relative;
  text-align: center;
}

.productSearchFragment-titleSpacing {
  padding-right: 4px;
}

.itemInquiryWidget_card-stateLayout SVG {
  width: 40%;
}

.image {
  margin-right: 12px;
  border-radius: 10px;
  overflow: hidden;
}

.image .back, .image .next {
  position: absolute;
  top: 50%;
  background-color: white;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.image:hover .back, .image:hover .next {
  opacity: 0.6;
}

.image .back:disabled, .image .next:disabled {
  opacity: 0 !important;
  cursor: default;
}

.image .back {
  left: 10px;
}

.image .next {
  right: 10px;
}