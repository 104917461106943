.aggregation-seperator {
  margin-left: 5px;
}

.size-compliance-body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.size-compliance-facets {
  height: 100%;
  margin-right: 10px;
  overflow-x: hidden;
  flex-shrink: 0;
  flex-basis: 300px;
}

.size-compliance-table {
  flex-grow: 10;
  margin-left: 5px;
  margin-top: 67px;
}

.size-compliance-aggregation {
  display: flex;
  flex-basis: 50px;
  flex-shrink: 0;
  justify-content: flex-end;
}

.size-compliance-clear-facets-button {
  overflow: hidden;
  position: fixed;
  font-size: 0.95em;
  grid-area: clearButton;
  border: 1px solid black;
  bottom: 0;
  width: 130px;
  height: 45px;
  margin: 10px 0;
  padding-top: 2px;
  border-radius: 32px;
  font-weight: bold;
  font-size: 0.83em;
  height: 32px;
  background: #fff;
  padding: 0 24px;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
}

.size-compliance-clear-facets-button:hover {
  color: var(--whiteDefault);
  background-color: var(--blackDefault);
  border: 1px solid black;
}

.size-compliance-table-header {
  padding: 4px 56px 4px 24px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 500;
}

.size-compliance-table-header-disabled {
  cursor: default;
}

.size-compliance-table-header-selected {
  color: rgba(0, 0, 0, 1);
  font-weight: 550;
}

@media screen and (min-width: 320px) and (max-width: 1366px) and (orientation: landscape) {
  .size-compliance-table {
    width: 660px !important;
  }
}
