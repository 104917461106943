.report .title-block {
  align-items: center;
}

.report .title-block .dropdown-block {
  position: relative;
  left: 40px;
}

.report .header {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  padding-right: 25px;
}

.report .header > * {
  flex: 1 1 auto;
}

.report .header-left {
  flex-direction: column;
  margin-right: 15px;
  max-width: 500px;
}

.report .header-right {
  display: flex;
  min-height: 200px;
  justify-content: center;
  margin-bottom: 15px;
}

.loading-indicator {
  position: relative;
}

.loading-indicator svg {
  top: 40%;
}

@media only screen and (max-width: 1080px) {
  .report .header-right {
    flex-direction: column!important;
    align-items: center;
  }
}