.dot-flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #292929;
  color: #292929;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .25s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #292929;
  color: #292929;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #292929;
  color: #292929;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: .70s;
}

.loading-flex {
  display: flex;
  align-items: center;
}

.loading-flex .dot-flashing {
  margin-right: 16px;
}

@keyframes dotFlashing {
  0% {
    background-color: #292929;
  }
  50%,
  100% {
    background-color: #979595;
  }
}