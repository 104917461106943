.style-management .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1600px;
    padding-top: 15px;
  }
  .style-management .header-left {
    flex-direction: column;
    margin-right: 12px;
  }
  .style-management .header-right {
    display: flex;
    flex-direction: column;
    width: 350px;
    padding-top: 10px;
  }
  .style-management-table-loading-indicator {
    position: relative;
  }
  
  .style-management-table-loading-indicator svg {
    top: 40%;
  }

  .salesFloor-indicator {
    font-size: 22px;
    color: orange;
  }
  
  .style-management-date {
    font-size: 16px;
    display: flex;
    align-items: baseline;
  }
  
  div.zeroSOH {
    background-image: linear-gradient(to bottom, #e4c6c7, #e78b9a);
    border-radius: 5px;
    color: #5f111d;
  }
  