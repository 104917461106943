.transfers-page .feature-title {
  margin-top: 15px;
}

.transfers-page .page-controls {
  margin-left: 50px;
  flex-grow: 1;
  justify-content: space-between;
}

.transfers-page .page-controls .left-side {
  justify-content: flex-end;
  align-items: flex-end;
}

.transfers-page .page-controls .left-side .date-picker-block {
  top: 0;
}

.transfers-page .page-controls .print-option-block {
  position: relative;
  top: 15px;
}

.transfers-page .detail {
  border-top: 1.5px solid #666666;
}

.transfers-page .bottom-row td {
  border-bottom: 1.5px solid #666666;
}

.transfers-page .bold {
  font-weight: 700;
}

.transfers-page .no-border {
  border-bottom: none;
}

.transfers-report-table {
  margin-top: 30px;
  padding-bottom: 40px;
}

.transfer-print-loading-spinner .loadingIndicator {
  position: static;
  height: 160px;
}

.transfer-print-modal-innards {
  text-align: center;
}

.transfer-print-modal-innards .transfer-print-text {
  margin: 10px;
  font-size: 16px;
}

.transfer-print-modal-innards .transfer-print-error {
  font-size: 42px;
}

.iPad .transfers-page .print-option-block {
  display: none;
}

@media print {
  /* this makes each carton within a transfer end up on its own page */
  .print-source .print-carton-table {
    break-before: always !important;
  }

  .print-source td,
  .print-source th {
    font-size: 10px;
    padding: 0 16px;
    height: 30px;
  }

  .print-source .bold,
  .print-source th {
    font-weight: 700;
  }
}
