.address-modal-container {
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.address-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 250px;
  min-width: 500px;
  max-width: 800px;
  background-color: hsl(0,0%,100%);
  border: 1px solid var(--blackDefault);
  box-shadow: -2rem 2rem 2rem rgba(134, 109, 109, 0.2);
  padding: 30px 50px;
  border-radius: 35px;
  filter: blur(0);
  outline: none;
}

.address-modal-form {
  width: 100%;
}

.address-modal-form-inputs {
  justify-content: space-around;
  width: 100%;
}

.address-modal-form-inputs label {
  flex-grow: 1;
  align-self: flex-start;
  margin: 2em;
}

.address-modal-form-inputs input {
  margin-top: 1em;
}

.address-modal-textField-wrapper {
  flex-grow: 3;
  width: 100%;
}

.address-modal-form-footer {
  width: 100%;
  justify-content: space-around;
}

.address-modal-form-footer button {
  margin-left: 5em;
}

.address-modal-description {
  text-align: center;
  margin: auto;
  font-size: 2em;
}

.address-modal-button {
  margin-top: 2em;
  border-radius: 6px;
  font-weight: bold;
  font-size: larger;
  height: 50px;
  min-width: 75px;
  background: var(--blackDefault);
  padding: 0 24px;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
  color: var(--whiteDefault);
  border: 1px solid var(--blackDefault);
}
