.styleComplianceWidget_dashboard {
  min-width: 360px;
  min-height: 300px;
  box-shadow: 0;
  text-decoration: none;
  margin: 10px 0 15px 0;
  background-color: var(--widgetBackgroundColor);
  padding: 10px;
}

.styleComplianceWidget_title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 30px;
  padding-bottom: 5px;
}

.styleComplianceWidget_title, .styleComplianceWidget_date {
  margin-left: 25px;
}

.styleComplianceWidget_dashboard a {
  margin-top: 20px;
}

.styleComplianceWidget_dashboard-widget:hover {
  text-decoration: none;
}


.styleComplianceWidget_dashboard p {
  color: var(--whiteDefault) !important;
}

.styleComplianceWidget_card-body p {
  font-weight: bold;
}

.styleComplianceWidget_card-loading {
  position: relative;
  top: 65px;
}

.styleComplianceWidget_card-error {
  text-align: center;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 52px
}

.styleComplianceWidget_no-data {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.styleComplianceWidget_data_visuals {
  display: flex;
  justify-content: space-evenly;
}

.styleComplianceWidget_generating_missing_styles {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 0 #9880ff;
  animation: dot-falling 1s infinite linear;
  animation-delay: 0.1s;
}
.dot-falling::before, .dot-falling::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-falling-before 1s infinite linear;
  animation-delay: 0s;
}
.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-falling-after 1s infinite linear;
  animation-delay: 0.2s;
}

@keyframes dot-falling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9999px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-before {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
@keyframes dot-falling-after {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%, 50%, 75% {
    box-shadow: 10014px 0 0 0 #9880ff;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}