.facet-group {
  color: var(--grey-disabled1);
  padding-top: 8px;
  margin: 8px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
}

.facet-group-title {
  padding-bottom: 5px;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}

.facet-slide {
  animation: .25s ease-out 0s 1 slideInFromLeft;
}

.facet-hidden {
  transform: translateX(-100%);
  animation: .25s ease-out 0s 1 slideOutToLeft;
}

.facet {
  display: flex;
  justify-content: space-between;
  transition: .5s;
  flex-wrap: nowrap;
  align-items: center;
}

.facet-label {
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .7px;
  margin: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translate(0,6px);
  max-width: 180px;
}

.facet-checkbox-wrapper {
  white-space: nowrap;
}

.facet-checkbox {
  margin-top: 3px;
}

.facet-label-selected {
  color: var(--blackDefault);
  font-weight: bold;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}