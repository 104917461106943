.details-widget-container {
  min-width: 550px;
  max-width: 700px;
  min-height: 350px;
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.details-widget-loading .loadingIndicator svg {
  position: relative;
  top: 120px;
  width: -moz-fit-content;
  width: fit-content;
}

.details-widget-title {
  text-align: center;
  padding-bottom: 10px;
}