.productRefillWidget_dashboard-card {
  height: 250px;
  margin: 0 0 15px 5px;
}

.productRefill_card-loading {
  position: relative;
  bottom: 10px;
}

.productRefillWidget_title {
  color: var(--whiteDefault);
  font-family: 'Futura', sans-serif;
  text-align: center;
  text-transform: uppercase;
  line-height: 30px;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 2.5em;
}

.productRefillWidget_dashboard-card-details {
  color: var(--whiteDefault);
  font-size: 17px;
  font-weight: 300;
  padding: 0px 30px;
}

.productRefill_error {
  color: var(--whiteDefault);
  display: flex;
  flex-direction: column;
  align-items: center;
}
