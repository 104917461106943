.clear-facets-button {
  overflow: hidden;
  position: fixed;
  font-size: 0.95em;
  grid-area: clearButton;
  border: 1px solid black;
  bottom: 0;
  width: 130px;
  height: 45px;
  margin: 10px 0;
  padding-top: 2px;
  border-radius: 32px;
  font-weight: bold;
  font-size: 0.83em;
  height: 32px;
  background: #fff;
  padding: 0 24px;
  transition-property: margin-right, background-color, color, border-color;
  transition-duration: 250ms;
}

.clear-facets-button:hover {
  color: var(--whiteDefault);
  background-color: var(--blackDefault);;
  border: 1px solid black;
}