.scan-report-summary-counts {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.scan-report-summary-counts-values {
  white-space: pre;
}

.scan-report-summary-table-header {
  padding: 4px 56px 4px 13px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  text-align: left;
}

.scan-report-summary-table-header-disabled {
  cursor: default;
}

.scan-report-summary-table-header-selected {
  color: var(--blackDefault);
  font-weight: 550;
}