.gridBorderStyles {
    border: 1px solid #a385c2;
    margin-top: 14px;
}

.hrStyles {
    border-top: 2px solid #8c8b8b; 
    width: 90%;
} 

.printDropZoneContainer {
    color: var(--blackDefault);
    page-break-inside: avoid; 
    margin-bottom: 30px !important;
} 

.printDropZoneFooterContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;
} 

.printDropZoneFooterCheckboxLabel {
    font-size: 1rem;
    color: var(--blackDefault);
} 
