.adjustments-dropdown-table.dropdown-table-detail {
  padding-top: 0;
  border-bottom: 1px solid #999;
}

.adjustments-dropdown-table.dropdown-table-detail table thead tr {
  height: 32px;
  background: #fff;
  font-weight: 400;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: .875rem;
  color: #1a1a1a;
  letter-spacing: .02em;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #999;
}

.adjustments-dropdown-table tbody tr:first-child td,
.adjustments-dropdown-table tbody tr:last-child td {
  border-top: 1.5px solid #999;
}

.adjustments-dropdown-table th,
.adjustments-dropdown-table td {
  width: 5%;
  min-width: 75px;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 0;
}

.adjustments-dropdown-table tbody tr {
  border: none;
  font-size: .875rem;
}

.adjustments-dropdown-table .total {
  color: #666;
  font-weight: 400;
  font-size: .8375em;
}

.adjustments-dropdown-table td.red {
  color: var(--red-fail);
}

.adjustments-dropdown-table td.green {
  color: var(--green-pass);
}

.adjustments-dropdown-table td.price.red {
  &::before {
    content: "(";
  }
  &::after {
    content: ")";
  }
}

.adjustments-dropdown-table .left {
  text-align: left;
  padding-left: 32px;
}

.adjustments-dropdown-table .wide {
  width: 20%;
}

.adjustments-dropdown-table .tight {
  width: 6%;
}