.summary-widget-container {
  height:100%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summary-widget-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.summary-widget-subMessage {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.2em;
}

.summaryReport_totals-error {
  color: var(--whiteDefault);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summary-widget-loading .loadingIndicator svg {
  position: relative;
  top:  -30px;
  left: -5px;
  width: 65px;
}

.summary-widget-data {
  color: var(--whiteDefault);
  display: grid;
  grid-template-columns: 175px 60px;
  grid-template-rows: auto;
}

.summary-widget-data-value {
  text-align: right;
}

.summary-widget-data p {
  margin-bottom: 0;
  position: relative;
  font-size: 1.2em;
}
