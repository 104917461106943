.stock-on-hand-summary-table {
  justify-content: flex-start;
}

[data-testid="stockOnHand-header"] .visual-summaries-container {
  justify-content: flex-start;
  margin-bottom: -10px;
}

.stock-on-hand-summary-container .stock-on-hand-summary-table {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 5px 0;
  border: 2px solid #eee;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: none;
  color: #1A1A1A;
  min-height: 180px;
  width: 400px;
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .loadingIndicator {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .loadingIndicator svg {
  padding: 0;
  margin: 0;
  position: initial;
  height: 120px;
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .stock-on-hand-summary-table-row {
  display: flex;
  flex-direction: row;
  padding: 2px 16px 5px;
  text-transform: uppercase;
  justify-content: space-between;
  font-size: 0.8125em;
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .stock-on-hand-summary-table-row.labels {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #1A1A1A;
  border-bottom: 1px solid black;
  margin-bottom: 5px;
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .stock-on-hand-summary-table-row:last-child {
  border-top: 1px solid black;
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .stock-on-hand-summary-table-row div {
  text-align: right;
  width: 100px;
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .stock-on-hand-summary-table-row div:first-child {
  text-align: left;
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .stock-on-hand-summary-table-row .value {
  font-weight: bold;
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .negative {
  color: var(--red-error);
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .negative::before {
  content: "(";
}

.stock-on-hand-summary-container .stock-on-hand-summary-table .negative::after {
  content: ")";
}
