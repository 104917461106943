.item-inquiry-page .header {
  align-items: center;
}

.item-inquiry-page .header .form {
  width: 410px;
  margin-left: 50px;
}

.item-inquiry-page .header .form div:first-child {
  width: 70%
}

.item-inquiry-page .content {
  margin-top: 20px;
}

.item-inquiry-page .content .results {
  background-color: #FFF;
}

.item-inquiry-page .content .results .productCard {
  margin-bottom: 20px;
}

.item-inquiry-page .content .shoebox {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  pointer-events: none;
  width: 300px;
}

.item-inquiry-page .content .shoebox h5 {
  margin-top: -60px;
}

.item-inquiry-page .content .shoebox-enter,
.item-inquiry-page .content .results-enter {
  opacity: 0;
}

.item-inquiry-page .content .shoebox-enter-active,
.item-inquiry-page .content .results-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.item-inquiry-page .content .shoebox-exit,
.item-inquiry-page .content .results-exit {
  opacity: 1;
}

.item-inquiry-page .content .shoebox-exit-active,
.item-inquiry-page .content .results-exit-active  {
  opacity: 0;
  transition: opacity 200ms;
}
