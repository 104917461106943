.item-activity-modal .feature-header .summary-box {
    margin: 5px;
    width: 240px;
}

.item-activity-modal .feature-header .style-box {
    font-family: Helvetica, Arial, sans-serif;
    line-height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.item-activity-modal .feature-header .date-box {
    font-family: Helvetica, Arial, sans-serif;
    line-height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 10px 10px 10px;
}

.item-activity-modal .feature-header .key {
    font-weight: normal;
    color: #666;
    font-size: 11px;
    text-transform: uppercase;
}

.item-activity-modal .feature-header .value {
    font-weight: bold;
    color: #1a1a1a;
    font-size: 13px;
    text-transform: uppercase;
}

.item-activity-modal .feature-title {
    display: flex;
    justify-content: space-between;
}
