.sizeComplianceWidget_dashboard {
  min-width: 360px;
  min-height: 300px;
  box-shadow: 0;
  text-decoration: none;
  margin: 10px 0 15px 0;
  background-color: var(--widgetBackgroundColor) !important;
  padding: 10px;
}

.sizeComplianceWidget_dashboard-widget:hover {
  text-decoration: none;
}

.sizeComplianceWidget_title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 30px;
  padding-bottom: 5px;
  margin-left: 25px;
}

.sizeComplianceWidget_data_visuals {
  display: flex;
  justify-content: space-evenly;
  margin-top: 45px;
}

.sizeComplianceWidget_dashboard-widget p {
  color: var(--whiteDefault) !important;
}

.sizeComplianceWidget_card-body p {
  font-weight: bold;
}

.sizeComplianceWidget_card-loading {
  position: relative;
  top: 65px;
}

.sizeComplianceWidget_card-error {
  text-align: center;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 52px;
}

.sizeComplianceWidget_no-data {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}
