.dropdown-summary-table {
  cursor: pointer;
}

.dropdown-summary-table {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 56px;
  background: #EEE;
  margin-top: 2px;
}

.dropdown-table-summary-cell {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 16px 0 32px;
}

.dropdown-table-summary-cell.right {
  margin-left: auto;
}

.dropdown-table-summary-cell.right ~ .dropdown-table-summary-cell.right {
  margin-left: inherit;
}

.dropdown-table-summary-cell .label {
  margin: 1px 0;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.6875em;
  color: #666;
  letter-spacing: 0.02em;
}

.dropdown-table-summary-cell .value {
  font-family: Helvetica, Arial, sans-serif;
  text-transform: none;
  font-size: 1em;
  color: #1A1A1A;
}

.dropdown-table-summary-cell .small-value {
  font-family: Helvetica, Arial, sans-serif;
  text-transform: none;
  font-size: .75em;
  color: #1A1A1A;
}

.dropdown-table-summary-cell.right {
  text-align: right;
}

.dropdown-table-summary-cell.checkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height:100%;
  width: 64px;
  padding: 5px 0;
}

.dropdown-table-summary-cell.checkbox img, .dropdown-table-summary-cell.checkbox .loadingIndicator {
  height: 20px;
  width: initial;
}

.dropdown-table-summary-cell.checkbox .loadingIndicator svg {
  width: 40px;
  height: 40px;
  padding: 0;
  position: relative;
  left: initial;
  margin-left: 0;
  margin-top: -10px;
}

.dropdown-table-container .dropdown-table-summary-cell.arrow {
  width: 1%;
}

.dropdown-table-container .dropdown-table-summary-cell.arrow svg {
  overflow: hidden;
  fill: #1a1a1a;
  width: 8px;
  height: 7px;
  margin-bottom: 2px;
  transition: all 0.5s ease;
}

.dropdown-table-container.collapsed .dropdown-table-summary-cell.arrow svg {
  transform: rotate(-90deg);
}

.dropdown-table-detail {
  transition: all 0.5s ease;
  overflow: hidden;
}

.dropdown-table-container.collapsed > .dropdown-table-detail {
  max-height: 0 !important;
}

.dropdown-table-detail table {
  border-spacing: 0;
  border-bottom: 1px solid #999;
  background-color: transparent;
  width: 100%;
  overflow: hidden;
}

.dropdown-table-detail table thead tr {
  height: 32px;
  padding: 0 16px 0 0;
  background: #c6c6c6;
  font-weight: normal;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.6875em;
  color: #1a1a1a;
  letter-spacing: 0.02em;
  border-top: 1px solid #eee;
}

.dropdown-table-detail table tbody tr {
  font-family: Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8125em;
  color: #1a1a1a;
  border-top: 1px solid #EEE;
}

.dropdown-table-detail table tbody tr:first-of-type {
  border-top: none;
}

.dropdown-table-detail table tbody tr:hover {
  background: #EEE;
}

.dropdown-table-detail table td.left {
  text-align: left;
  padding-left: 32px;
}

.dropdown-table-detail table td.red {
  color: #f71c2d;
}

.dropdown-table-detail table tr td {
  padding: 6px 16px 6px 0;
}

@media print {
  .dropdown-table-container .arrow {
    display: none;
  }

  .dropdown-table-container .checkbox {
    display: none;
  }

  .dropdown-table-container.collapsed .dropdown-table-detail, .dropdown-table-container:not(.print-hidden) .dropdown-table-detail {
    max-height: inherit !important;
  }

  .dropdown-table-container.print-hidden {
    display: none;
  }
}
