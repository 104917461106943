.asn-tracking-page .feature-title {
  margin-top: 15px;
  align-items: flex-end;
}

.asn-tracking-page .page-controls {
  margin-left: 50px;
  flex-grow: 1;
  justify-content: space-between;
  flex-wrap: wrap;
}

.asn-tracking-page .page-controls .date-picker-block{
 left: 0px
}

.asn-tracking-page .page-controls .print-button-block {
  justify-content: flex-end;
}

.asn-tracking-page .page-controls .print-button-block .flex-row {
  align-items: center;
}

.asn-tracking-page .page-controls .print-button-block .button {
  margin-right: 15px;
}

.iPad .asn-tracking-page .page-controls .print-button-block {
  display: none;
}

.iPad .asn-tracking-page .details .dropdown-table-summary-cell.checkbox {
  display: none;
}

.asn-tracking-page
  .details
  .dropdown-table-container
  .dropdown-summary-table
  .docNumber {
  width: 15%;
}

.asn-tracking-page
  .details
  .dropdown-table-container
  .dropdown-summary-table
  .shipDate {
  width: 10%;
}

.asn-tracking-date {
  display: flex;
  align-items: baseline;
}

.asn-tracking-date p {
  position: relative;
  top: 4px;
}

.asn-tracking-page .details .tables .custom-table:not(:first-child) thead {
  opacity: 0;
  border: 0 none;
  height: 0px;
}

.asn-tracking-page .details .tables .custom-table:not(:first-child) thead * {
  margin: 0;
  padding: 0;
  border: 0 none;
  height: 0px;
  line-height: 0px;
}

.asn-tracking-page .details .tables .custom-table td {
  width: 10%;
  text-align: center;
  padding: 6px 16px 6px 0;
  text-transform: uppercase;
}

.asn-tracking-page .details .tables .custom-table td.left {
  text-align: left;
  padding-left: 32px;
}

.asn-tracking-page .details .tables .custom-table td.leftFlush {
  text-align: left;
  padding-left: 0;
}

.asn-tracking-page .details .tables .custom-table td.tight {
  width: 6%;
}

.asn-tracking-page .details .tables .custom-table td.wide {
  width: 20%;
}

.asn-tracking-page .details .tables .custom-table thead td {
  height: 32px;
  padding: 0 16px 0 0;
}

.asn-tracking-page .details .tables tbody tr {
  border: none;
}

.asn-tracking-page .details .tables tbody td {
  border-top: 1px solid #eee;
}

.asn-tracking-page .details .tables tbody .totals td {
  border-top: 1px solid #999;
  font-weight: bold;
}

.asn-tracking-page .details .tables .totals .left {
  color: #666;
  font-weight: normal;
  font-size: 0.8375em;
}

.asn-tracking-page .details .tables tbody td.left:not(.wide) {
  border: none;
}

.asn-dialogTitle span {
  justify-content: center;
  align-items: center;
}

.asn-dialogTitle .button {
  margin-right: 10px;
}

.asn-search {
  display: flex;
  width: 300px;
  margin-top: 8px;
}

.asn-dialogTitle button {
  border: none;
}

.asn-dialogTitle button svg {
  width: 30px;
}

.search-container input {
  width: 200px;
}

@media print {
  .asn-tracking-page .page-controls {
    display: none;
  }
}